import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from 'src/app/shared/enum/sweep-content.enum';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  public pageDesc = PageDesc;
  @Input() name = '';

  @Output() closeSuccessModal: EventEmitter<void> = new EventEmitter();


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  closeModal() {
    if (this.activeModal) {
      this.activeModal.close();
    }

    this.closeSuccessModal.emit();
  }
}

