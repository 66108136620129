<div class="modal-header">
  <span class="modal-title">Confirmation</span>
  <span  class="close-btn" (click)="closeModal()">
    <i class="icon ion-md-close">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path
    d="M7.77611 9.99992L0 17.7761L2.22394 20L10 12.2238L17.7762 20L20.0001 17.7761L12.224 9.99992L19.9998 2.22394L17.776 0L10 7.77601L2.22407 0L0.000255949 2.22394L7.77611 9.99992Z"
    fill="white"/>
</svg>
    </i>
  </span>
</div>
<div class="modal-body">
  <div class="content">
    <div class="content-body">
      <p>
        {{ message }}
      </p>
    </div>

    <div class="action-btns">
      <button
        *ngIf="!disable"
        class="primary-button"
        type="button"
        (click)="confirm()"
      >
        Ok
      </button>

      <button
        *ngIf="disable"
        class="primary-button"
        type="button"
        (click)="confirm()"
      >
        <div class="spinner-border" role="status"></div>
      </button>
    </div>
  </div>
</div>

<ng-template #empty>
  <span></span>
</ng-template>
