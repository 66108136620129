import { Injectable, NgZone } from '@angular/core';
import { SweepService } from './sweep.service';

@Injectable({
  providedIn: 'root',
})
export class SSWInjector {
  private injections: { [key: string]: Function[]; } = {};
  allowedInjectors = [
    'setSSWSweepDetails',
  ];

  listen(injectorName: string, fn: Function) {
    if (!this.injections[injectorName]) {
      throw new Error('Undefined injector. Name: ' + injectorName);
    }

    this.injections[injectorName].push(fn);
  }

  remove(injectorName: string) {
    this.injections[injectorName] = null!;
  }

  run(injectorName: string, args: any) {
    if (!this.injections[injectorName]) {
      throw new Error('Undefined injector.');
    }

    this.injections[injectorName].forEach((fn) => {
      fn(args);
    });
  }

  constructor(private localZone: NgZone) {
    this.allowedInjectors.forEach((injectorName) => {
      this.injections[injectorName] = [];
    });

    (window as any)['runSSWAngularEvent'] = (injectorName: string, args: any) => {
      if (!this.injections[injectorName]) {
        throw new Error('Undefined injector.');
      }

      this.localZone.run(() => {
        this.run(injectorName, args);
      });
    };
  }
}

