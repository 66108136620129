export enum PageDesc {
  STATION = "Station:",
  ACC_FLAG = "A/C FAA Reg #:",
  SHIP = " Ship #:",
  DATE = "Date: ",
  FLIGHT_SELECT_MSG = "Please select what measures applies to this flight.",
  FULL = "Full",
  CANADIAN_VISUAL = "Canadian Visual",
  INTERNATIONAL_STATION = "International Mainline Stations Only (Not DC Flights)",
  OUTBOUND_FLIGHT_NUMBER = "Outbound Flight Number:",
  FLIGHT_ORIGIN = "Flight Origin:",
  FLIGHT_DESTINATION = "Flight Destination:",
  RESWEEP = "RESWEEP",
  NEXT = "PROCEED",
  NOTES = "Notes",
  COMPLETED_DATE = "Completed Date",
  SEARCH_AGENTS = "Search Agents",
  FLIGHT_NUMBER_INVALID_MSG = "Invalid Flight Number",
  FLIGHT_NUMBER_EMPTY_MSG = "The Flight Number have Null or Empty Value",
  FAA_REG_NO_INVALID_MSG = "Invalid FaaRegistration Number",
  FAA_REG_NO_EMPTY_MSG = "The FaaRegistration Number have Null or Empty Value",
  SHIP_NO_INVALID_MSG = "Invalid Ship Number",
  SHIP_NO_EMPTY_MSG = "The Ship Number have Null or Empty Value",
  LogOut_MSG = "Are You Sure You Want To Logout?",
  GROUP_ACCESS_RESTRICTION_MSG = "Access restricted. Station not authorized",
  Confirmation_MSG = "Confirming the notes and names of the inspectors will also stamp the time when the security check was conducted.",
  Confirmation_MSG_ReSweep = "Confirm you are conducting a new Aircraft Search.",
  Submitted = "Submitted by",
  SUCCESS = "The form has been successfully submitted.",
  TOAST = "Something went wrong. Pleas try again",
  FLIGHT_RELEASE = "Note: Confirmation of the search of the flight deck is acknowledged by the signing of the flight release",
  LAST_NAME = "Last Name is Required",
  VALID_SHIPNUMBER = " Please Enter Ship number",
  UNABLE_SUBMIT = "Unable to submit data. Plese try again",
  CONFIRM_CARGO = " Please confirm that the Aircraft Baggage/Cargo Bin Search has been performed and completed.",
  CONFIRM_INT = " Please confirm that aircraft interior search has been performed and completed as required.",
  CONFIRM_EXT = "  Please confirm that the Aircraft Exterior Search has been performed and completed.",
  INVALID_FLIGHTINFO = "Invalid flight information",
  inspector = "Individuals who conducted the search : ",
  FLIGHT_APPLIED = "Measure applied to flight: ",
  FAA_REQUIRED = "FAA Registration Number is Required",
  FAA_INVALID = "Invalid FAA Registration Number",
  STATION_REQUIRED = "Station is Required",
  FLIGHT_NUMBER_REQUIRED = "Flight Number is Required",
  DESTINATION_REQUIRED = "Destination is Required",
  ORIGIN_REQUIRED = "Origin is Required",

}

