<div class="d-flex flex-column">
  <form #sweepDetailsFormT="ngForm">
    <div class="row aircraft-detail">
      <div class="col-lg-6">
        <ng-content></ng-content>
      </div>

      <div class="col-lg-6 notes-and-inspector">
        <!--<form [formGroup]="sweepDetailsForm"> -->

        <div class="form-group">
          <label>Notes:</label>
          <div>
            <textarea type="text" class="form-control rounded-0" rows="3" name="notes" id="notes" [disabled]="isEdit"
              value="{{Notes}}" ngModel="{{ Notes }}"></textarea>
          </div>

          <label class="sub-label">200 Characters only</label>
        </div>
        <div class="form-group mt-3">
          <label>Name of the search agents:</label>
          <label style="float: right">
            <a href="javascript:void(0);" style="text-decoration: none" (click)="addcrewTeamsTable()">&#43;
              AddMore</a></label>
          <div class="d-none d-md-block" style="overflow: scroll; height: 300px">
            <table style="border-collapse: separate; border-spacing: 0 15px">
              <tr *ngFor="let crewTeam of crewTeamsTable; let i = index">
                <td style="padding: 5px">
                  <input placeholder="First Name" type="text" class="form-control rounded-0"
                    name="{{ 'crewTeamFirstName_' + typeOfSweep + '_' + i }}"
                    id="{{ 'crewTeamFirstName_' + typeOfSweep + '_' + i }}"
                    value="{{ loadAgentsVal.get(i)?.firstName }}" disabled="{{ isEdit }}"
                    onkeydown="return /[a-z]/i.test(event.key)" (change)="validateFirstNames(typeOfSweep, i)"
                    ngModel="{{ loadAgentsVal.get(i)?.firstName }}" />
                </td>

                <td style="padding: 5px">
                  <input placeholder="Last Name" type="text" class="form-control rounded-0"
                    [ngClass]="{ 'input-error': lastNameError.get(i) }"
                    name="{{ 'crewTeamLastName_' + typeOfSweep + '_' + i }}"
                    id="{{ 'crewTeamLastName_' + typeOfSweep + '_' + i }}" #lastNameToast="ngModel"
                    disabled="{{ isEdit }}" onkeydown="return /[a-z]/i.test(event.key)"
                    (change)="validateLastNames(typeOfSweep, i)" ngModel="{{ loadAgentsVal.get(i)?.lastName }}" />
                  <mat-error *ngIf="lastNameError.get(i)">Last Name is Required</mat-error>
                </td>
              </tr>
            </table>
          </div>

          <div class="d-block d-md-none" style="min-height: 300px">
            <table style="border-collapse: separate; border-spacing: 0 15px">
              <tr *ngFor="let crewTeam of crewTeamsTable; let i = index">
                <td style="padding: 5px">
                  <input placeholder="First Name" type="text" class="form-control rounded-0"
                    name="{{ 'crewTeamFirstName_' + typeOfSweep + '_' + i }}"
                    id="{{ 'crewTeamFirstName_' + typeOfSweep + '_' + i }}"
                    value="{{ loadAgentsVal.get(i)?.firstName }}" disabled="{{ isEdit }}"
                    onkeydown="return /[a-z]/i.test(event.key)" ngModel="{{ loadAgentsVal.get(i)?.firstName }}" />
                </td>

                <td style="padding: 5px">
                  <input placeholder="Last Name" type="text" class="form-control rounded-0"
                    [ngClass]="{ 'input-error': lastNameError.get(i) }"
                    name="{{ 'crewTeamLastName_' + typeOfSweep + '_' + i }}"
                    id="{{ 'crewTeamLastName_' + typeOfSweep + '_' + i }}" #lastNameToast="ngModel"
                    disabled="{{ isEdit }}" onkeydown="return /[a-z]/i.test(event.key)"
                    ngModel="{{ loadAgentsVal.get(i)?.lastName }}" />
                  <mat-error *ngIf="lastNameError.get(i)">{{pageDesc.LAST_NAME}}</mat-error>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <label class="sub-label"></label>
      </div>
      <!--</form> -->
    </div>
    <!-- </div> -->

    <div class="row">
      <div class="form-group my-2 text-center">

        <ng-container *ngIf="!isEdit else disabledonebutton">
          <button id="doneButton" class="primary-button me-4" type="button" (click)="confirm(sweepDetailsFormT.value)">
            Done </button>
        </ng-container>
        <ng-template #disabledonebutton>
          <button class="primary-button me-4" type="button" [class.disabled]="true" [disabled]="true"> Done </button>
        </ng-template>




        <ng-container *ngIf=" !sweepDetails.editable && isEdit else disablebutton">
          <button class="primary-button" type="button" [class.disabled]="isEdit" (click)="editSweepDetails(typeOfSweep)"
            [disabled]="!sweepDetails.editable">Edit</button>
        </ng-container>
        <ng-template #disablebutton>
          <button class="primary-button" type="button" [class.disabled]="!isEdit"
            (click)="editSweepDetails(typeOfSweep)" [disabled]="!sweepDetails.editable">Edit</button>
        </ng-template>


      </div>
    </div>
    <ng-container *ngIf="typeOfSweep === 'INT'">
      <div class="row">
        <div class="form-group mt-3">
          <label class="search-note">
            {{pageDesc.FLIGHT_RELEASE}} </label>
        </div>
      </div>
    </ng-container>
  </form>
</div>