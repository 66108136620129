import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SweepDetailEditValue, loadAgents } from 'src/app/model/editSweep.model';

import { NGXLogger } from 'ngx-logger';
import { PageDesc } from 'src/app/shared/enum/sweep-content.enum';
import { SweepDetails } from 'src/app/model/sweepDetails.model';
import { SweepType } from 'src/app/shared/enum/sweep-type.enum';
import { TabConfig } from '../../sweepchecklist/sweepchecklist.component';
import { Validation } from 'src/app/shared/service/validation';

@Component({
  selector: 'app-sweep-detail',
  templateUrl: './sweep-detail.component.html',
  styleUrls: ['./sweep-detail.component.scss'],
})
export class SweepDetailComponent implements OnInit {
  public sweepDetails!: SweepDetails;

  @Input()
  set sweepDetail(sweeep: SweepDetails) {
    this.exteriorBaggageSweep = sweeep;

    this.updateNotesAndCrew(sweeep);
    this.sweepDetails = sweeep;

  }

  public enableByType: Map<string, boolean> = new Map<string, boolean>();
  @Output() editSweep: EventEmitter<void> = new EventEmitter();
  @Output() confirmEmt: EventEmitter<SweepDetailEditValue> =
    new EventEmitter<SweepDetailEditValue>();

  public exteriorBaggageSweep!: SweepDetails;
  public pageDesc = PageDesc;

  public sweepDetailsForm!: FormGroup;
  public sweepDetailsFormT!: FormGroup;
  public crewTeamsT: any = [];
  public crewTeamIndex: number = 1;
  public crewTeamLength: number = 0;

  public crewTeamsTable: any = ["1", "2", "3", "4"];
  public isEdit: boolean = true;
  public isPost: boolean = true;
  public showError: boolean = true;
  public isUpdate: boolean = false;
  public lastNameError: Map<Number, boolean> = new Map<Number, boolean>();
  public loadAgentsVal: Map<Number, loadAgents> = new Map<Number, loadAgents>();
  public loadAgentsData !: loadAgents;
  public Notes !: string | '';
  public typeOfSweep!: string;
  public notesEditable: boolean = true;
  public newCrewTeams: string = '';
  public regxLetters = /^[A-Za-z]+$/;
  public disableDoneButton: Map<string, boolean> = new Map<string, boolean>();
  public intDisableDoneButton: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private readonly validation: Validation
  ) {
    this.createSweepDetailsForm();
    this.enableByType.set('INT', true);
    this.enableByType.set('BIN', true);
    this.enableByType.set('EXT', true);

  }

  ngOnInit(): void {
    console.log(this.sweepDetailsFormT);
    if (this.crewTeamLength > 4) {
      for (let i = this.crewTeamsTable.length; i < this.crewTeamLength; i++) {
        this.crewTeamsTable.push(this.crewTeamsTable.length + 1);
      }

    }

  }

  public createSweepDetailsForm() {
    let formBuilder = this.formBuilder;
    if (!formBuilder) {
      formBuilder = new FormBuilder;
    }
    this.sweepDetailsForm = formBuilder.group({
      notes: '',
      crewTeam: '',

      isPostAPI: true,
    });
  }

  crewTeams(): FormArray {
    return this.sweepDetailsForm.get("crewTeamsT") as FormArray;
  }

  newCrewTeam(): FormGroup {
    return this.formBuilder.group({
      crewTeamFirstName: '',
      crewTeamLastName: ''
    })
  }

  addCrewTeam() {

    return this.crewTeamsT.push(this.newCrewTeam());
  }

  addcrewTeamsTable() {
    this.crewTeamsTable.push(this.crewTeamsTable.length + 1);
  }


  //retriving details from select component and displaying on ui
  public updateNotesAndCrew(sweepDetail: SweepDetails) {

    if (sweepDetail) {
      //mapping note value
      let notes = '' + sweepDetail.notes;
      this.Notes = notes;
      this.typeOfSweep = sweepDetail.typeOfSweep;
      let inspectorName = '' + sweepDetail.inspectorName;
      let inspectorId = '' + sweepDetail.inspectorId;
      let teams = sweepDetail.crewTeam;
      this.newCrewTeams = teams;
      if (sweepDetail.notes == '') {
        this.notesEditable = false;
      }


      //mapping the  first name last name array of values
      //if((null != inspectorId && inspectorId != '') && (null != inspectorName && inspectorName != '') && (null != teams && teams != '')) {
      if ((null != teams && teams != '')) {
        let teamsArray = teams.split(';');
        let teamsLength = teamsArray.length;
        this.crewTeamLength = teamsLength;
        for (let i = 0; i < teamsLength; i++) {
          let agents = teamsArray[i].split(',');
          if (i == 0 || agents.length == 2) {
            this.loadAgentsData = {
              firstName: agents[0],
              lastName: agents[1]
            };
          } else {
            this.loadAgentsData = {
              firstName: agents[1],
              lastName: agents[2]
            };
          }
          this.loadAgentsVal.set(i, this.loadAgentsData);
        }
      } else {
        //disable the edit and enable the fields for new data
        this.isEdit = false;
        this.isUpdate = true;
        this.loadAgentsVal.clear();

      }

      //disable the edit emitter based on the type.
      if ((null == notes || notes == '') && (null == teams || teams == '')) {
        this.editSweep.emit();
        this.isEdit = false;
        this.enableByType.set(this.typeOfSweep, false);
        this.disableDoneButton.set(this.typeOfSweep, true);

      }
      if ((null != notes || notes != '') && (null != teams || teams != '')) {
        this.disableDoneButton.set(this.typeOfSweep, false);
        this.intDisableDoneButton = false;
      }

      if (String(notes) == 'SmartTrack' && (sweepDetail.typeOfSweep == SweepType.INTERIOR || sweepDetail.typeOfSweep == SweepType.SMARTrack)) {
        this.isEdit = true;
        this.Notes = '';
        this.editSweepDetails('INT');

      }




    }

    if (this.isUpdate) {
      // this. editSweepDetails();

    }

  }
  //enableing the edit and disable post api
  public editSweepDetails(typeOfSweep: string) {
    this.editSweep.emit();
    this.isEdit = false;
    this.isPost = false;
    this.notesEditable = false;
    this.intDisableDoneButton = false;
    this.disableDoneButton.set(this.typeOfSweep, true);

  }

  public confirm(sweepDetailsFormT: any) {
    let isSubmit = true;
    let crewTeam = "";
    let notes = "";
    if (sweepDetailsFormT) {
      notes = sweepDetailsFormT.notes
    }

    for (let rowValue = 0; rowValue < this.crewTeamsTable.length; rowValue++) {
      let isError = false;

      let firstName = sweepDetailsFormT['crewTeamFirstName_' + this.typeOfSweep + '_' + rowValue];
      let lastName = sweepDetailsFormT['crewTeamLastName_' + this.typeOfSweep + '_' + rowValue];

      // If both first name & last name is present
      if ((null != firstName && firstName != '') && (null != lastName || lastName != '')) {
        crewTeam += firstName + ',' + lastName;
        crewTeam += ';';
      }
      // If first name is present & last name is empty
      if ((null != firstName && firstName != '') && (null == lastName || lastName == '')) {
        isError = true;
        isSubmit = false;
      }
      this.lastNameError.set(rowValue, isError);
    }


    // return false;
    if (!this.notesEditable && this.isEdit) {
      crewTeam = this.newCrewTeams;
    }

    this.sweepDetailsForm.setValue({ notes: notes, crewTeam: crewTeam, isPostAPI: this.isPost }); // ,  inspectorTimeStamp: moment().format('HH:mm')


    if (isSubmit) {
      if (this.isUpdate) {
        this.sweepDetailsForm.setValue({ notes: notes, crewTeam: crewTeam, isPostAPI: this.isPost }); // ,  inspectorTimeStamp: moment().format('HH:mm')
      } else {
        this.sweepDetailsForm.setValue({ notes: notes, crewTeam: crewTeam, isPostAPI: this.isPost });
      }

      if (this.sweepDetailsForm.value) {
        this.confirmEmt.emit(this.sweepDetailsForm.value);
        //disable all fields after submit
        this.isEdit = true;
      }
    }

  }
  public validateFirstNames(typeOfSweep: string, row: number) {
    let firstName = (<HTMLInputElement>document.getElementById('crewTeamFirstName_' + typeOfSweep + '_' + row)).value;
    let isLetters = this.validation.validateAllLeters(firstName);
    if (!isLetters)
      (<HTMLInputElement>document.getElementById('crewTeamFirstName_' + typeOfSweep + '_' + row)).value = '';

  }
  public validateLastNames(typeOfSweep: string, row: number) {
    let lastName = (<HTMLInputElement>document.getElementById('crewTeamLastName_' + typeOfSweep + '_' + row)).value;
    let isLetters = this.validation.validateAllLeters(lastName);
    if (!isLetters)
      (<HTMLInputElement>document.getElementById('crewTeamLastName_' + typeOfSweep + '_' + row)).value = '';

  }

  /*validateNames(event: any, sweepDetailsFormT: any) {
    const namesArr = [];
    for (let rowValue = 0; rowValue < this.crewTeamsTable.length; rowValue++) {
      let firstName = sweepDetailsFormT['crewTeamFirstName_' + this.typeOfSweep + '_' + rowValue];
      let lastName = sweepDetailsFormT['crewTeamLastName_' + this.typeOfSweep + '_' + rowValue];
      if (firstName !== '' && lastName !== '') {
        namesArr.push(`${firstName}, ${lastName}`);
      }
    }

    if (namesArr.length > 0) {
      this.searchAgentNamePresent = true;
      this.showSearchAgentError = false;
    } else {
      this.searchAgentNamePresent = false;
      this.showSearchAgentError = true;
    };
  }*/
}
