<div class="modal-header">
    <span class="modal-title">Confirmation</span>
    <span class="close-btn" (click)="closeModal()">
      <i class="icon ion-md-close"></i>
    </span>
  </div>
  <div class="modal-body">
    <div class="content">
      <div class="content-body">
        <p>
          {{pageDesc.SUCCESS}}
        </p>
      </div>

      <div class="action-btns">
        <button class="primary-button" type="button" (click)="closeModal()">
          Done
        </button>
      </div>
    </div>
  </div>
