import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import * as moment from 'moment';
import { NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationModalComponent } from '../common/confirmation-modal/confirmation-modal.component';
import { EditSweepRequest } from './../../model/editSweep.model';
import { FlightMeasure } from 'src/app/shared/enum/flight-measure.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from 'src/app/shared/enum/sweep-content.enum';
import { SaveSweepRequest } from 'src/app/model/saveSweep.model';
import { SessionStorageService } from 'ngx-webstorage';
import { SecuritySweep } from 'src/app/model/securitySweep.model';
import {
  SecuritySweepRequestData,
  SweepFlightData,
} from '../../model/sweepUI.model';
import { SuccessModalComponent } from '../common/success-modal/success-modal.component';
import { SweepDetailEditValue } from 'src/app/model/editSweep.model';
import { SweepDetails } from 'src/app/model/sweepDetails.model';
import { SweepRequest } from 'src/app/shared/service/sweep-request';
import { SweepService } from 'src/app/shared/service/sweep.service';
import { SweepType } from 'src/app/shared/enum/sweep-type.enum';
import { TimePipe } from 'src/app/shared/pipes/time.pipe';
import { ToastService } from 'src/app/toast/toast-service';
import { UserService } from 'src/app/shared/service/user.service';
import { Validation } from 'src/app/shared/service/validation';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  Output,
  EventEmitter,
} from '@angular/core';

const redirectUrl: string =
  origin.includes('si') ? environment.idp_si.redirectUri :
    (origin.includes('dev') ? environment.idp.redirectUri :
      (origin.includes('localhost') ? environment.idp_localhost.redirectUri :
        (origin.includes('securitysweep-console.delta.com') ? environment.idp_ssw_prd.redirectUri : environment.idp_prd.redirectUri)));

export interface TabConfig {
  sweepType: SweepType;
  title: string;
  show: boolean;
  time: string;
  isEdit: boolean;
  editable: boolean;
}
@Directive({
  selector: '[appTimeInputClick]',
})
export class TimeInputClickDirective implements OnInit {
  @Output() timeInputClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    const inputElement = this.el.nativeElement.querySelector('.ngb-tp-input');
    this.renderer.listen(inputElement, 'click', () => {
      this.timeInputClick.emit();
    });
  }
}

@Component({
  selector: 'app-sweepchecklist',
  templateUrl: './sweepchecklist.component.html',
  styleUrls: ['./sweepchecklist.component.scss'],
})
export class sweepchecklistComponent implements OnInit, OnDestroy {
  public readonly SweepType = SweepType;

  public securitySweepData!: SecuritySweep;
  public sweepData!: Array<SweepDetails>;

  public sweepType: SweepType = SweepType.INTERIOR;
  public flightMeasure!: FlightMeasure;
  public isFullMeasure: boolean = true;

  private sweepDetatilsFromUrl!: any;
  public securitySweepRequestData!: SecuritySweepRequestData;
  private saveSweepRequest!: SaveSweepRequest;
  private editSweepRequest!: EditSweepRequest;
  currentDate = moment().format('YYYY-MM-DD');
  sweepSearchArry: any = {};

  public sweepConfigList: Array<TabConfig> = [
    {
      sweepType: SweepType.INTERIOR,
      title: 'Aircraft Interior',
      show: true,
      time: '',
      isEdit: false,
      editable: false,
    },
    {
      sweepType: SweepType.CARGOBINS,
      title: 'Aircraft Baggage/Cargo bins',
      show: true,
      time: '',
      isEdit: false,
      editable: false,
    },
    {
      sweepType: SweepType.EXTERIOR,
      title: 'Aircraft Exterior',
      show: true,
      time: '',
      isEdit: false,
      editable: false,
    },
  ];
  public minDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  public maxDate = moment().format('YYYY-MM-DD');
  public loading: boolean = true;
  public isResetEnabled: boolean = false;
  public timeInput: boolean = false;
  public isUpdate: boolean = false;
  public isSave: boolean = false;
  public isPostApi: boolean = false;
  public selectedTime: any = new Date();
  public error: boolean = false;
  public isNewData: boolean = false;
  public completionTimeStamp: Map<string, string> = new Map<string, string>();
  public searchsweepId: Map<string, string> = new Map<string, string>();
  public selectedTimeByType: Map<string, string> = new Map<string, string>();
  public selectedIndex: number = -1;

  public Station!: string;
  public IsCanadianVisual!: boolean;
  public OutboundFlightNum!: string;
  public Flightorigin!: string;
  public FlightDestination!: string;
  public pageDesc = PageDesc;
  public flightNum!: string;
  public airlineCode!: string;
  public faaRegistgrationNo!: string;
  public crewFullNameList!: string;
  public applicationId!: string;
  public sweepFlightData!: SweepFlightData;
  public enableSaveAPIByType: Map<SweepType, boolean> = new Map<
    SweepType,
    boolean
  >();
  public displayCategoryTimeByType: Map<SweepType, any> = new Map<
    SweepType,
    any
  >();

  meridian = false;
  public intTime!: { hour: number, minute: number };
  public extTime!: { hour: number, minute: number };
  public binTime!: { hour: number, minute: number };
  public isResweep: boolean = false;
  public isResweepSaved: boolean = false;
  public mainLine: boolean = false;
  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  // public currentTime: any =   moment().format('HH:mm');

  private _destroyer: Subject<void> = new Subject();

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activateRoute: ActivatedRoute,
    private readonly modalService: NgbModal,
    private sweepService: SweepService,
    private timePipe: TimePipe,
    private readonly validation: Validation,
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly sweepRequest: SweepRequest,
    private readonly logger: NGXLogger,
    private cdr: ChangeDetectorRef,
    private readonly location: Location,
    public storage: SessionStorageService
  ) {
    // this.flightMeasure = this.router.getCurrentNavigation()?.extras?.state?.['flightMeasure']; //res.get('flightMeasure') as FlightMeasure;
  }

  ngOnInit() {
    this.renderSweepChecklist();
  }
  public renderSweepChecklist() {
    if (this.storage) {
      this.sweepFlightData = this.storage.retrieve('sweepFlightDetails');
    }
    this.sweepSearchArry = { date: this.currentDate };
    this.mapSweepCheckListDetails(this.sweepFlightData);
    this.sweepConfigList.forEach((config) => {
      config.isEdit = false;
    });
    this.enableSaveAPIByType.set(SweepType.CARGOBINS, false);
    this.enableSaveAPIByType.set(SweepType.EXTERIOR, false);
    this.enableSaveAPIByType.set(SweepType.INTERIOR, false);
    this.displayCategoryTimeByType.set(
      SweepType.CARGOBINS,
      moment().format('HH:mm')
    );
    this.displayCategoryTimeByType.set(
      SweepType.EXTERIOR,
      moment().format('HH:mm')
    );
    this.displayCategoryTimeByType.set(
      SweepType.INTERIOR,
      moment().format('HH:mm')
    );

    if (this.sweepFlightData.applicationID == 'INT') {
      this.intTime = {
        hour: Number(moment().format('HH')),
        minute: Number(moment().format('mm')),
      }
    } else if (this.sweepFlightData.applicationID == 'BIN') {
      this.binTime = {
        hour: Number(moment().format('HH')),
        minute: Number(moment().format('mm')),
      }
    } else if (this.sweepFlightData.applicationID == 'EXT') {
      this.extTime = {
        hour: Number(moment().format('HH')),
        minute: Number(moment().format('mm')),
      }
    }

    // this.displayCategoryTimeByType.set(SweepType.INTERIOR,  moment().format('HH:mm'));
    if (this.sweepService.getCanadianFlightMeasure()) {
      this.sweepConfigList = [{
          sweepType: SweepType.INTERIOR,
          title: 'Aircraft Interior',
          show: true,
          time: '',
          isEdit: false,
          editable: false,
        },
        {
          sweepType: SweepType.CARGOBINS,
          title: 'Aircraft Baggage/Cargo bins',
          show: false,
          time: '',
          isEdit: false,
          editable: false,
        },
        {
          sweepType: SweepType.EXTERIOR,
          title: 'Aircraft Exterior',
          show: false,
          time: '',
          isEdit: false,
          editable: false,
        },]
    }
  }

  public mapSweepCheckListDetails(sweepFlightData: SweepFlightData) {
    this.flightMeasure = sweepFlightData.flightMeasure as FlightMeasure;
    this.isFullMeasure = this.flightMeasure === FlightMeasure.FULL;
    this.sweepConfigList[1].show = !!this.isFullMeasure;
    this.sweepConfigList[2].show = !!this.isFullMeasure;

    this.securitySweepRequestData = {
      departureDate: sweepFlightData.departureDate,
      shipNum: sweepFlightData.shipNumber,
      stationCode: sweepFlightData.stationCode,
      filtered: true,
    };
    this.Station = sweepFlightData.departureStationCode;
    this.flightNum = sweepFlightData.flightNum;
    this.faaRegistgrationNo = sweepFlightData.faaRegistrationNum || '';
    this.IsCanadianVisual = sweepFlightData.isCanadianVisual ? true : false;
    this.OutboundFlightNum = this.OutboundFlightNum;
    this.Flightorigin = sweepFlightData.flightOrigin;
    this.FlightDestination = sweepFlightData.flightDestination;
    this.crewFullNameList = sweepFlightData.crewFullNameList;
    this.applicationId = sweepFlightData.applicationID;
    this.isResweep = sweepFlightData.isResweep;
    this.airlineCode = this.flightNum && this.validation.ValidateAlphaNumeric(this.flightNum) ? this.flightNum.substring(0, 2) : 'DL';
    this.mainLine = sweepFlightData.mainLine ? sweepFlightData.mainLine : false;
    this.fetchData();
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  public preventDefault(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  public changeTab(tab: TabConfig, index: number) {
    index === this.selectedIndex ? this.selectedIndex = -1 : this.selectedIndex = index;
    this.toggleTab(tab);
  }

  public toggleTab(tab: TabConfig) {
    this.sweepType = tab.sweepType;

    if (this.securitySweepData) {
      this.sweepData = JSON.parse(
        JSON.stringify(this.securitySweepData.sweepDetails)
      );
    }

    /*let index = 0;
    this.sweepConfigList.forEach((config) => {
      if(config.sweepType == this.sweepData[index].typeOfSweep){

      }
     // config.isEdit = this.timeInput;// false;
      index++;
    });*/
    this.assignTimeInConfig();
    if (null != this.timePipe && this.timePipe) {
      this.timePipe = new TimePipe();
      this.selectedTime = this.timePipe.transform(tab.time);
    }

    this.intTime =
      tab.sweepType == 'INT'
        ? {
            hour: Number(moment().format('HH')),
            minute: Number(moment().format('mm')),
          }
        : { hour: 0, minute: 0 };
    this.binTime =
      tab.sweepType == 'BIN'
        ? {
            hour: Number(moment().format('HH')),
            minute: Number(moment().format('mm')),
          }
        : { hour: 0, minute: 0 };
    this.extTime =
      tab.sweepType == 'EXT'
        ? {
            hour: Number(moment().format('HH')),
            minute: Number(moment().format('mm')),
          }
        : { hour: 0, minute: 0 };
  }

  public backToHome() {
    this.router.navigate(['/home'], {});
  }

  //The isEdit is true enable time field is ediatble.
  public makeSweepEditable(index: number) {
    if (this.sweepData) {
      this.sweepData[index] = {
        ...this.sweepData[index],
        isEdit: true,
      };
    }

    this.sweepConfigList[index].isEdit = true;
  }

  private assignTimeInConfig() {
    let sweepConfigListValue = this.sweepConfigList;
    if (!sweepConfigListValue) {
      let sweepConfigList: Array<TabConfig> = [
        {
          sweepType: SweepType.INTERIOR,
          title: 'Aircraft Interior',
          show: true,
          time: '',
          isEdit: false,
          editable: false,
        },
        {
          sweepType: SweepType.CARGOBINS,
          title: 'Aircraft Baggage/Cargo bins',
          show: !this.sweepService.getCanadianFlightMeasure(),
          time: '',
          isEdit: false,
          editable: false,
        },
        {
          sweepType: SweepType.EXTERIOR,
          title: 'Aircraft Exterior',
          show: !this.sweepService.getCanadianFlightMeasure(),
          time: '',
          isEdit: false,
          editable: false,
        },
      ];
      sweepConfigListValue = sweepConfigList;
    }
    let sweepDataValue = this.sweepData;
    if (!sweepDataValue) {
      sweepDataValue: Array<SweepDetails>();
    }
    if (this.isSave) {
      sweepConfigListValue[0].time = '';
      sweepConfigListValue[1].time = '';
      sweepConfigListValue[2].time = '';
      this.selectedTime = '';
    } else {
      sweepConfigListValue[0].time = sweepDataValue[0].locInspectorTimeStamp
        ? sweepDataValue[0].locInspectorTimeStamp
        : '';
      sweepConfigListValue[1].time = sweepDataValue[1].locInspectorTimeStamp
        ? sweepDataValue[1].locInspectorTimeStamp
        : '';
      sweepConfigListValue[2].time = sweepDataValue[2].locInspectorTimeStamp
        ? sweepDataValue[2].locInspectorTimeStamp
        : '';

      if (null != this.timePipe && this.timePipe) {
        this.timePipe = new TimePipe();
        this.selectedTime = this.timePipe.transform(
          sweepConfigListValue[0].time
        );
      }
    }
  }

  public success() {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.closeSuccessModal.subscribe(() => {
      if (this.getUserAgent() === 'mobile') {
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 1000);
      } else if (!this.sweepService.getCanadianFlightMeasure() && this.sweepConfigList.filter(itm => itm.isEdit == false).length < 2) {
        this.renderSweepChecklist();
      } else {
        this.backToHome();
      }
    });
  }

  // API Call
  private fetchData() {
    if (
      this.securitySweepRequestData.shipNum &&
      this.securitySweepRequestData.departureDate 
    ) {
      this.searchApi();
    } else {
      this.error = true;
      this.loading = false;
      if (this.toastService) {
        this.toastService.show(PageDesc.INVALID_FLIGHTINFO, {
          classname: 'bg-danger text-light',
          delay: 5000,
        });
      }
    }
  }

  private searchApi() {
    this.loading = true;

    if (
      !this.securitySweepRequestData.shipNum ||
      !this.securitySweepRequestData.departureDate
    ) {
      return;
    }

    // API call

    this.sweepService
      .search(this.securitySweepRequestData)
      .pipe(takeUntil(this._destroyer))
      .subscribe(
        (res) => {
          this.mapSearchAPIResponse(res);
          this.loading = false;
          this.error = false;
        },
        (err) => {
          if (err.sweepResponseStatusCode == '404') {
            this.mapSearchAPIErrorResponse();
            this.error = false;
            this.loading = false;
          } else {
            this.error = true;
            this.loading = false;
          }
        }
      );
  }
  public mapSearchAPIResponse(res: any) {
    //checking based on app id is smartrack then passing crew names to search api response
    let isSmartTrack = false;
    if (this.applicationId == SweepType.INTERIOR || this.applicationId == SweepType.SMARTrack
    ) {
      isSmartTrack = true;
    }
    this.securitySweepData = this.sweepService.modifyDataStructure(res, isSmartTrack, this.crewFullNameList);

    if (this.isResweep && !this.securitySweepData.isResweep) {
      this.crewFullNameList = '';
      this.mapSearchAPIErrorResponse();
    }
    else if (this.isResweep && this.securitySweepData.isResweep && !this.isResweepSaved) {
      this.crewFullNameList = '';
      this.mapSearchAPIErrorResponse();
    }
    else {
      this.securitySweepData = this.sweepService.modifyDataStructure(res, isSmartTrack, this.crewFullNameList);
    }

    let index = 0;
    if (this.securitySweepData) {
      this.securitySweepData.sweepDetails.forEach((sweep) => {
        if (sweep.typeOfSweep == 'INT') {
          if (sweep.inspectorTimeStamp && sweep.inspectorTimeStamp.length > 0) {
            this.sweepSearchArry = { date: sweep.inspectorTimeStamp.split(' ')[0] };
          } else {
            this.sweepSearchArry = { date: this.currentDate };
          }
          this.intTime = {
            hour: Number(sweep.locInspectorTimeStamp?.split(':')[0]),
            minute: Number(sweep.locInspectorTimeStamp?.split(':')[1]),
          };
        }
        else if (sweep.typeOfSweep == 'BIN')
          this.binTime = {
            hour: Number(sweep.locInspectorTimeStamp?.split(':')[0]),
            minute: Number(sweep.locInspectorTimeStamp?.split(':')[1]),
          };
        else
          this.extTime = {
            hour: Number(sweep.locInspectorTimeStamp?.split(':')[0]),
            minute: Number(sweep.locInspectorTimeStamp?.split(':')[1]),
          };

        let swpId = sweep.sweepId + '';
        let swpCompletionDt = sweep.sweepCompletionDateAndTimeStamp + '';
        this.completionTimeStamp.set(sweep.typeOfSweep, swpCompletionDt);
        this.searchsweepId.set(sweep.typeOfSweep, swpId);
        this.selectedTimeByType.set(
          sweep.typeOfSweep,
          sweep.inspectorTimeStamp
        );
      });
      this.checkResetEnabled();

      this.sweepData = JSON.parse(
        JSON.stringify(this.securitySweepData.sweepDetails)
      ) as Array<SweepDetails>;

      this.assignTimeInConfig();
      this.securitySweepData.sweepDetails.forEach((sweep) => {
        if (this.sweepData[index].typeOfSweep == sweep.typeOfSweep) {
          this.sweepData[index].isEdit = sweep.editable;
          this.enableSaveAPIByType.set(
            sweep.typeOfSweep,
            sweep.editable ? sweep.editable : false
          );
          this.sweepConfigList.forEach((config) => {
            config.editable = sweep.editable ? sweep.editable : false;
            //config.isEdit = sweep.editable? sweep.editable : false;
          });
        }
        index++;
      });
      //checking the new data or no based on the condition
      this.securitySweepData.sweepDetails.forEach((sweep: SweepDetails) => {
        
        if (sweep.typeOfSweep == SweepType.INTERIOR && sweep.notes == 'SmartTrack') {
          this.isNewData = true;

        }

      });
      this.isResweep = this.securitySweepData.isResweep;

    }
  }

  public mapSearchAPIErrorResponse() {
    let validation = this.validation;
    if (!this.validation) {
      validation = new Validation();
    }

    this.buildDataFromParams();
    let SEARCH_DATA = this.searchApiResponse();
    SEARCH_DATA.listOfSweeps[0].flightNum = this.flightNum
      ? validation.getFlightNum(this.flightNum) || ''
      : '';
    SEARCH_DATA.listOfSweeps[0].departureDate =
      this.securitySweepRequestData.departureDate;
    SEARCH_DATA.listOfSweeps[0].airlineCode = this.flightNum && validation.ValidateAlphaNumeric(this.flightNum) ? this.flightNum.substring(0, 2) : 'DL';
    SEARCH_DATA.listOfSweeps[0].shipNum = this.securitySweepRequestData.shipNum;
    SEARCH_DATA.listOfSweeps[0].faaRegistrationNum = this.faaRegistgrationNo;
    SEARCH_DATA.listOfSweeps[0].stationCode = this.Station;
    SEARCH_DATA.listOfSweeps[0].flightOrigin = this.Flightorigin;
    SEARCH_DATA.listOfSweeps[0].flightDestination = this.FlightDestination;
    SEARCH_DATA.listOfSweeps[0].cadVisual = this.IsCanadianVisual;
    SEARCH_DATA.listOfSweeps[0].isResweep = this.isResweep;
    SEARCH_DATA.listOfSweeps[0].mainLine = this.mainLine;
    this.securitySweepData = this.sweepService
      ? this.sweepService.modifyDataStructure(SEARCH_DATA, false, '')
      : this.securitySweepData;
    this.securitySweepData.sweepDetails.forEach((sweep) => {
      if (!sweep.inspectorTimeStamp) {
        sweep.inspectorTimeStamp = moment().format('HH:mm');
      }
      //sweep.inspectorTimeStamp = '';
      if (this.isResweep && !this.securitySweepData.isResweep && !(sweep.typeOfSweep == SweepType.INTERIOR || sweep.typeOfSweep == SweepType.SMARTrack)) {
        sweep.locInspectorTimeStamp = '';
      } else if (this.isResweep && this.securitySweepData.isResweep && !this.isResweepSaved && !(sweep.typeOfSweep == SweepType.INTERIOR || sweep.typeOfSweep == SweepType.SMARTrack)) {
        sweep.locInspectorTimeStamp = '';
      }

      let swpId = sweep.sweepId + '';
      let swpCompletionDt = sweep.sweepCompletionDateAndTimeStamp + '';
      this.completionTimeStamp.set(sweep.typeOfSweep, swpCompletionDt);
      this.searchsweepId.set(sweep.typeOfSweep, swpId);
      this.selectedTimeByType.set(sweep.typeOfSweep, sweep.inspectorTimeStamp);
      this.sweepConfigList.forEach((config) => {
        config.editable = sweep.editable ? sweep.editable : false;
      });
    });
    this.checkResetEnabled();

    this.sweepData = JSON.parse(
      JSON.stringify(this.securitySweepData.sweepDetails)
    ) as Array<SweepDetails>;
    this.isPostApi = true;
    this.assignTimeInConfig();
    let index = 0;
    this.securitySweepData.sweepDetails.forEach((sweep) => {
      if (this.sweepData[index].typeOfSweep == sweep.typeOfSweep) {
        this.sweepData[index].isEdit = sweep.editable;
        this.enableSaveAPIByType.set(sweep.typeOfSweep, false);
        this.sweepConfigList.forEach((config) => {
          config.editable = sweep.editable ? sweep.editable : false;
          //config.isEdit = sweep.editable? sweep.editable : false;
        });
      }
      index++;
    });
  }

  private buildDataFromParams() {
    let validation = this.validation;
    if (!this.validation) {
      validation = new Validation();
    }
    let restSweepDetails: Array<SweepDetails> = [];
    this.securitySweepData = {
      flightNum: this.flightNum
        ? validation.getFlightNum(this.flightNum) || ''
        : '', //validation.getFlightNum(this.flightNum),
      airlineCode: this.flightNum && validation.ValidateAlphaNumeric(this.flightNum) ? this.flightNum.substring(0, 2) : 'DL',
      faaRegistrationNum: this.faaRegistgrationNo,
      shipNum: this.securitySweepRequestData.shipNum
        ? this.securitySweepRequestData.shipNum || ''
        : '',
      sweepDetails: this.sweepService
        ? JSON.parse(JSON.stringify(this.sweepService.ResetSweepDetails))
        : restSweepDetails,
      departureDate: this.securitySweepRequestData.departureDate
        ? this.securitySweepRequestData.departureDate || ''
        : '',
      flightDestination: '',
      flightOrigin: '',
      stationCode: this.securitySweepRequestData.stationCode
        ? this.securitySweepRequestData.stationCode || ''
        : '',
      isResweep: this.isResweep,
      searchId: '',
      sweepCreationDateAndTimeStamp: '',
      cadVisual: false,
      mainLine: false,
    };
  }

  public confirm(sweepEditValue: SweepDetailEditValue, index: number) {
    if (this.sweepData[index].typeOfSweep == 'INT') {
      const intSweepDate = `${this.sweepSearchArry.date} 00:00:00`;

      const timeEvent = new Date(intSweepDate).setHours(
        this.intTime.hour,
        this.intTime.minute
      );
      const time = new Date(timeEvent).toUTCString();
      const utcTime = moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
      this.selectedTime = `${utcTime} GMT`;

      // this.selectedTime = (this.intTime.hour.toString().length > 1 ? this.intTime.hour : '0' + this.intTime.hour) + ":" + (this.intTime.minute.toString().length > 1 ? this.intTime.minute : '0' + this.intTime.minute);
    } else if (this.sweepData[index].typeOfSweep == 'EXT') {
      const timeEvent = new Date().setHours(
        this.extTime.hour,
        this.extTime.minute
      );
      const time = new Date(timeEvent).toUTCString();
      const utcTime = moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
      this.selectedTime = `${utcTime} GMT`;

      // this.selectedTime = (this.extTime.hour.toString().length > 1 ? this.extTime.hour : '0' + this.extTime.hour) + ":" + (this.extTime.minute.toString().length > 1 ? this.extTime.minute : '0' + this.extTime.minute);
    } else {
      const timeEvent = new Date().setHours(
        this.binTime.hour,
        this.binTime.minute
      );
      const time = new Date(timeEvent).toUTCString();
      const utcTime = moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
      this.selectedTime = `${utcTime} GMT`;

      // this.selectedTime = (this.binTime.hour.toString().length > 1 ? this.binTime.hour : '0' + this.binTime.hour) + ":" + (this.binTime.minute.toString().length > 1 ? this.binTime.minute : '0' + this.binTime.minute);
    }

    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.confirmEvent.subscribe(() => {
      modalRef.componentInstance.disable = true;
      this.sweepData[index].inspectorTimeStamp = this.selectedTime;
      const requestBody = this.sweepRequest.buildEditSweepRequest(
        sweepEditValue,
        this.sweepData[index],
        this.securitySweepData,
        this.isResweep
      );
      let isPostApi = this.enableSaveAPIByType.get(
        this.sweepData[index].typeOfSweep
      )
        ? false
        : true; //sweepEditValue.isPostAPI ? true : false;
      //based on new data condition calling save api
      isPostApi = (typeof this.securitySweepData.searchId === "string" && this.securitySweepData.searchId.trim().length === 0);
      console.log('searchId', this.securitySweepData.searchId)
      this.sweepService
        .saveEditSweep(requestBody, isPostApi)
        .pipe(takeUntil(this._destroyer))
        .subscribe(
          (res) => {
            modalRef.componentInstance.disable = false;
            //based on condition new data is false
            this.isNewData = false;
            this.isResweep = false;
            this.isResweepSaved = true;
            modalRef.close();
            this.success();
          },
          (err) => {
            const msg = err.developerMessage ? err.developerMessage : (err.sweepMessage ? err.sweepMessage : 'Error saving data');
            this.toastService.show(msg, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
            modalRef.componentInstance.disable = false;
            modalRef.close();
          }
        );
    });
  }

  public reset() {
    if (!this.isResetEnabled) {
      return;
    }

    if (this.securitySweepData) {
      const index = this.securitySweepData.sweepDetails.findIndex(
        (sw) => sw.typeOfSweep == this.sweepType
      );

      this.securitySweepData.sweepDetails[index] = {
        inspectorTimeStamp: '',
        locInspectorTimeStamp: '',
        sweepId: '',
        notes: '',
        typeOfSweep: this.sweepType,
        inspectorName: '',
        inspectorId: '',
        crewTeam: '',
        sweepCompletionDateAndTimeStamp: '',
      };

      // this.logger.info('securitySweepData: ', this.securitySweepData)
      this.sweepData = JSON.parse(
        JSON.stringify(this.securitySweepData.sweepDetails)
      ) as Array<SweepDetails>;

      //this.assignTimeInConfig();
      this.assignTimeInConfigByType(this.sweepType);

      this.isUpdate = true;
    }
  }

  public ResetSweepDetailsByType(sweepType: any) {
    //this.logger.info('sweepType: ', sweepType);
    const ResetSweepDetails: SweepDetails = {
      inspectorTimeStamp: '',
      locInspectorTimeStamp: '',
      sweepId: '',
      notes: '',
      typeOfSweep: sweepType,
      inspectorName: '',
      inspectorId: '',
      crewTeam: '',
      sweepCompletionDateAndTimeStamp: '',
    };

    return ResetSweepDetails;
  }

  public assignTimeInConfigByType(sweepType: any) {
    this.selectedTimeByType.set(sweepType, '');
    if (sweepType == 'INT') {
      this.sweepConfigList[0].time = this.sweepData[0].locInspectorTimeStamp
        ? this.sweepData[0].locInspectorTimeStamp
        : '';
    } else if (sweepType == 'BIN') {
      this.sweepConfigList[1].time = this.sweepData[1].locInspectorTimeStamp
        ? this.sweepData[1].locInspectorTimeStamp
        : '';
    } else {
      this.sweepConfigList[2].time = this.sweepData[2].locInspectorTimeStamp
        ? this.sweepData[2].locInspectorTimeStamp
        : '';
    }

    if (null != this.timePipe && this.timePipe) {
      this.timePipe = new TimePipe();

      this.selectedTime = this.timePipe.transform(this.sweepConfigList[0].time);
    }
  }

  public checkResetEnabled() {
    const sweepDetails = this.securitySweepData.sweepDetails;
    if (null != sweepDetails && sweepDetails.length > 0) {
      this.isResetEnabled = !!(
        (sweepDetails[0].notes && sweepDetails[0].crewTeam) ||
        (sweepDetails[1].notes && sweepDetails[1].crewTeam) ||
        (sweepDetails[2].notes && sweepDetails[2].crewTeam)
      );
    }
  }

  inputTimeValue(sweepTypeValue: SweepType, activeType: boolean) {
    let timeValue = (<HTMLInputElement>(
      document.getElementById(sweepTypeValue + '_time')
    )).value;
    this.displayCategoryTimeByType.set(sweepTypeValue, timeValue);
    if (activeType) {
      this.selectedTime = timeValue;
    }
  }

  public inputClick(e: Event, sweepTypeValue: SweepType) {
    let timeValue = (<HTMLInputElement>(
      document.getElementById(sweepTypeValue + '_time')
    )).value;
    this.timeInput = true;

    e.stopPropagation();
  }

  public searchApiResponse() {
    let SEARCH_DATA = {
      listOfSweeps: [
        {
          searchId: '',
          stationCode: '',
          faaRegistrationNum: '',
          shipNum: '',
          flightNum: '',
          airlineCode: '',
          flightOrigin: '',
          flightDestination: '',
          departureDate: '',
          cadVisual: false,
          isResweep: false,
          sweepCreationDateAndTimeStamp: '',
          mainLine: false,
          sweepDetails: this.mapSweepDetails(),
        },
      ],
    };

    return SEARCH_DATA;
  }


  private mapSweepDetails() {
    let extTeam = '';
    let intTeam = '';
    let binTeam = '';
    let isEditable = false;
    if (this.applicationId == SweepType.EXTERIOR) {
      extTeam = this.crewFullNameList;
      isEditable = true;
    } else if (
      this.applicationId == SweepType.INTERIOR ||
      this.applicationId == SweepType.SMARTrack
    ) {
      intTeam = this.crewFullNameList;
      isEditable = true;
    } else {
      binTeam = this.crewFullNameList;
      isEditable = true;
    }
    //for enabling the done button and time
    return [
      {
        sweepCompleteLocalTimeStamp: '',
        notes: 'SmartTrack',
        typeOfSweep: SweepType.INTERIOR,
        inspectorTimeStamp: '',
        locInspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: intTeam,
        editable: isEditable,
      },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.CARGOBINS,
        inspectorTimeStamp: '',
        locInspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: binTeam,
        editable: isEditable,
      },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.SMARTrack,
        inspectorTimeStamp: '',
        locInspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: intTeam,
        editable: isEditable,
      },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.EXTERIOR,
        inspectorTimeStamp: '',
        locInspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: extTeam,
        editable: isEditable,
      },
    ];
  }

  getUserAgent(): string {
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    //Mozilla/5.0 (Linux; Android 8.0.0; SM-G955U Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Mobile Safari/537.36 Edg/105.0.0.0
    //Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1 Edg/105.0.0.0
    if (/SM|SAMSUNG/i.test(userAgent)) {
      console.log('-----samsung');
      return 'mobile';
    }
    //Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36 Edg/105.0.1343.53
    if (/Chrome|Mozilla|AppleWebKit|Safari|Edg/i.test(userAgent)) {
      return 'desktop';
    } else {
      return 'desktop';
    }
  }
}
