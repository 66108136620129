import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from './../../../shared/enum/sweep-content.enum';

@Component({
  selector: 'app-access-modal',
  templateUrl: './access-modal.component.html',
  styleUrls: ['./access-modal.component.scss']
})
export class AccessModalComponent implements OnInit {

  @Input() message = PageDesc.GROUP_ACCESS_RESTRICTION_MSG;

  @Output() confirmEventAccessModal: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  confirm() {
    this.confirmEventAccessModal.emit();
  }

  closeModal() {
    this.activeModal.close();
  }
}
