<!-- Begin page -->
<div class="row" id="printable">
  <div class="col-12">
    <!-- Topbar Start -->
    <div class="header-wrapper">
      <div class="logo-box text-white d-flex flex-column" style="margin-left: 35%">
        <h3 class="text-center">Sensitive Security information</h3>
        <h3 class="text-center">Aircraft Search Checklist</h3>
      </div>

      <div class="logo-container d-md-none flex-column">
        <img src="./assets/Images/DeltaPrint.svg" width="140" height="800" class="img-fluid my-auto rounded" />
      </div>
      <div class="logo-container d-none d-md-flex">
        <img src="./assets/Images/DeltaPrint.svg" width="150" height="900" class="img-fluid my-auto rounded" />
      </div>
    </div>

    <div class="d-flex page-title">
      <div class="page-title-left">
        <h4 class="welcome">
          Welcome {{ user.given_name }} {{ user.family_name }}
        </h4>
      </div>

      <div class="page-title-right">
        <!--<div class="address me-4">ATL - Atlanta, GA</div>-->
        <!-- <div class="date me-4">
          {{ date | date: "mediumDate" }}
        </div>

        <div class="time">
          {{ date | date: "HH:mm" }}
        </div> -->
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="flight-details">
          <div class="flight-detatil-info row">
            <div class="col-3 mb-md-0">
              <div class="info-field">
                <label> {{ pageDesc.STATION }} </label>
                <span class="value">
                  {{ securitySweepDataForPrint.stationCode }}</span>
              </div>
            </div>

            <div class="col-3 mb-md-0">
              <div class="info-field">
                <label> {{ pageDesc.ACC_FLAG }} </label>
                <span class="value">
                  {{ securitySweepDataForPrint.faaRegistrationNum }}</span>
              </div>
            </div>

            <div class="col-2 mb-md-0">
              <div class="info-field">
                <label> {{ pageDesc.SHIP }} </label>
                <span class="value">
                  {{ securitySweepDataForPrint.shipNum }}</span>
              </div>
            </div>

            <div *ngIf="securitySweepDataForPrint.cadVisual || securitySweepDataForPrint.sweepDetails.length > 2"
              class="col-4 mb-md-0">
              <div class="date-section">
                Aircraft Search Completion Date : {{ searchCompletionDate | date: "dd MMM yyyy" }}
              </div>
              <div class="time-section">
                Aircraft Search Completion Time : {{ searchCompletionTime }}
              </div>
            </div>
          </div>

          <div class="col-12 mt-1 flight-select-container">
            <div class="flight-select-label">
              {{ pageDesc.FLIGHT_SELECT_MSG }}
            </div>
            <div class="radio-container">
              <label> {{ pageDesc.FLIGHT_APPLIED }} </label>
              <span class="value">
                {{
                securitySweepDataForPrint.cadVisual
                ? pageDesc.CANADIAN_VISUAL
                : pageDesc.FULL
                }}</span>
            </div>
          </div>
        </div>

        <div class="flight-details-input-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="form-check form-check-checkbox mb-1 form-check-blue">
                <input class="form-check-input me-0 me-md-2" type="checkbox" disabled="disabled"
                  [checked]="securitySweepDataForPrint.mainLine" />
                <label class="form-check-label">
                  {{ pageDesc.INTERNATIONAL_STATION }}</label>
              </div>
            </div>
          </div>
          <div class="row flight-details-input" [class.disabled]="!checkboxEnable" *ngIf="securitySweepDataForPrint.mainLine">
            <div class="col-12 d-flex justify-content-start flex-row">
              <div class="form-group field mb-md-0" style="width: 33%;">
                <label class="col-form-label" style="white-space: nowrap">
                  {{ pageDesc.OUTBOUND_FLIGHT_NUMBER }}</label>
                <span class="value">
                  {{ securitySweepDataForPrint.flightNum }}</span>
              </div>
              <div class="form-group field mb-md-0" style="width: 33%;">
                <label class="col-form-label" style="white-space: nowrap">
                  {{ pageDesc.FLIGHT_ORIGIN }}</label>
                <span class="value">
                  {{ securitySweepDataForPrint.flightOrigin }}</span>
              </div>
              <div class="form-group field mb-md-0" style="width: 33%;">
                <label class="col-form-label" style="white-space: nowrap">
                  {{ pageDesc.FLIGHT_DESTINATION }}</label>
                <span class="value">
                  {{ securitySweepDataForPrint.flightDestination }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="sweep-details-wrapper mt-1">
          <div class="row w-100">
            <ng-container *ngFor="let item of sweepConfigListForPrint; let i = index">
              <div class="aircraft-detail interior col-4">
                <div class="sweep-details">
                  <div class="notes-and-sweep-details bar" *ngIf="i === 0">
                    <!--<div class="sweep-time">
                      <label class="col-form-label">{{ item.title }}:</label>
                      <span class="time-input">{{ item.time }} </span>
                    </div>-->

                    <!--<div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ item.completedDateTitle }}</label>
                      <div class="date-wrapper fw-bold">
                        {{ item.completedDate | date: "MMM d, y" }}
                      </div>
                    </div>-->
                    <div>
                      <label class="col-form-label">Aircraft Interior Checklist.</label>
                      <div class="description">
                        (Search areas applicable as required by search type applied)
                      </div>
                    </div>
                    <div class="description-list">
                      <ul>
                        <li>Overhead baggage compartments</li>
                        <li>Closets and interior compartments</li>
                        <li>Storage bins and other cabin compartments</li>
                        <li>Lavatories</li>
                        <li>Crew rest areas</li>
                        <li>Seats and immediate surrounding area</li>
                        <li>Life vests and life vest holders</li>
                        <li>Galleys</li>
                        <li>Trash receptacles</li>
                        <li>Air vents and other accessible compartments</li>
                      </ul>
                    </div>

                    <div>
                      <label class="col-form-label notes-label">
                        {{ pageDesc.NOTES }}
                      </label>
                    </div>

                    <div class="notes-container">
                      <div class="notes-wrapper">
                        {{ item.notes }}
                      </div>
                    </div>

                    <div class="notes-container pb-1">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.inspector }}</label>
                      <div class="notes-wrapper"></div>
                    </div>

                    <div class="notes-container">
                      <div class="inspectors">
                        <div class="inspectors-list">
                          <label *ngFor="let crew of item.crewTeam;  let j = index;" class="d-inline me-1">
                            <ng-container *ngIf="crew !== '' && j >= 0 && j < item.crewTeam.length">
                              {{ crew }};
                            </ng-container>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.Submitted }}</label>
                      <div class="submit-wrapper">
                        {{ item.inspectorName }}
                      </div>
                    </div>

                  </div>
                  <div class="notes-and-sweep-details bar" *ngIf="i > 0" [ngClass]="{ bar: i < 2 }">
                    <!--<div class="sweep-time">
                      <label class="col-form-label" style="white-space: nowrap">{{ item.title }}:</label>
                      <span class="time-input">{{ item.time  }} </span>
                    </div>-->

                    <!--<div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ item.completedDateTitle }}</label>
                      <div class="date-wrapperr fw-bold" style="
                            font-size: 12px;
                            padding: 5px;
                            white-space: nowrap;
                          ">
                        {{ item.completedDate | date: "MMM d, y" }}
                      </div>
                    </div>-->

                    <div>
                      <label class="col-form-label">{{
                        item.typeOfSweep === SweepType.EXTERIOR
                        ? "Aircraft Exterior"
                        : "Aircraft Baggage / Cargo bins"
                        }}
                        Checklist.</label>
                    </div>

                    <ng-container *ngTemplateOutlet="
                          item.typeOfSweep === SweepType.EXTERIOR
                            ? exterior
                            : cargo
                        "></ng-container>

                    <ng-template #exterior>
                      <div class="description-list">
                        <ul>
                          <li>Accessible Service Panels</li>
                        </ul>
                      </div>
                    </ng-template>

                    <ng-template #cargo>
                      <div class="description-list">
                        <ul>
                          <li>Baggage and Cargo Bins</li>
                        </ul>
                      </div>
                    </ng-template>

                    <div>
                      <label class="col-form-label notes-label">
                        {{ pageDesc.NOTES }}
                      </label>
                    </div>

                    <div class="notes-container">
                      <div class="notes-wrapper">
                        {{ item.notes }}
                      </div>
                    </div>

                    <div class="notes-container pb-1">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.inspector }}</label>
                      <div class="notes-wrapper"></div>
                    </div>

                    <div class="notes-container">
                      <div class="inspectors">
                        <div class="inspectors-list">
                          <label *ngFor="let crew of item.crewTeam;  let k = index;" class="d-inline me-1">
                            <ng-container *ngIf="crew !== '' && k >= 0 && k < item.crewTeam.length">
                              {{ crew }};
                            </ng-container>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.Submitted }}</label>
                      <div class="submit-wrapper">
                        {{ item.inspectorName }}
                      </div>
                    </div>

                  </div>
                </div>

                <div class="sweep-details" *ngIf="canadianFlightMeasure && i === 0">
                  <div class="notes-and-sweep-details">
                    <!--<div class="sweep-time">
                      <label class="col-form-label">{{ item.title }}:</label>
                      <span class="time-input">{{ item.time}} </span>
                    </div>-->

                    <!--<div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ item.completedDateTitle }}</label>
                      <div class="date-wrapper fw-bold">
                        {{ item.completedDate | date: "MMM d, y" }}
                      </div>
                    </div>-->
                    <div class="description-list">
                      <ul>
                        <li>Accessible Service Panels</li>
                      </ul>
                    </div>

                    <div>
                      <label class="col-form-label notes-label">
                        {{ pageDesc.NOTES }}
                      </label>
                    </div>

                    <div class="notes-container">
                      <div class="notes-wrapper">
                        {{ item.notes }}
                      </div>
                    </div>

                    <div class="notes-container pb-1">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.inspector }}</label>
                      <div class="notes-wrapper"></div>
                    </div>

                    <div class="notes-container">
                      <div class="inspectors">
                        <div class="inspectors-list">
                          <label *ngFor="let crew of item.crewTeam; let i = index" class="d-inline me-1">
                            <ng-container *ngIf="crew !== '' && i >= 0 && i < item.crewTeam.length">
                              {{ crew }};
                            </ng-container>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="notes-container">
                      <label class="col-form-label notes-label">
                        {{ pageDesc.Submitted }}</label>
                      <div class="submit-wrapper">
                        {{ item.inspectorName }}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="card disclaimer-section">
      <div class="col-12">
        <p class="description">
          Warning: This record contains sensitive security information that is
          controlled under 49 c.f.r parts 15 and 1520. no part of this record
          may be disclosed to person without a "need to know" as defined in 49
          c.f.r. parts 15 and 1520, except with the written permission of the
          administrator of the transportation security or the secretary of
          transportation, unauthorized release may result in civil penalties or
          the other . for U.S government agencies, public disclosure governed by
          5 U.S.C 552 and 49 c.f.r. parts 15 and 1520.
        </p>
      </div>
      <div class="col-12 copyright">
        <p class="float-start">Copyright @ Delta Air Lines</p>

        <p class="float-end">
          <span class="me-2">v3.0.0</span>Revised December 19th, 2023
        </p>
      </div>
    </div>
  </div>
</div>