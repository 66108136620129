import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from './../../../shared/enum/sweep-content.enum';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent implements OnInit {

  @Input() message = PageDesc.LogOut_MSG;

  @Output() conformLogout: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  confirm() {
    this.conformLogout.emit();
  }

  closeModal() {
    this.activeModal.close();
  }

}
