import { Component, HostBinding, TemplateRef } from '@angular/core';

import { NgIf } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../toast-service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
})
export class ToastsContainerComponent {
  @HostBinding('class')
  toastContainerClass = 'toast-container position-fixed top-0 end-0 p-3';

  @HostBinding('style.z-index')
  toastContainerZIndex = '1200';

  constructor(public toastService: ToastService) { }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
