import * as moment from 'moment';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject, of, switchMap, throwError, timer } from 'rxjs';
import { SecuritySweepRequestData, SweepConfigListForPrint, SweepFlightData, SweepReportRequestData } from '../../model/sweepUI.model';

import { EditSweepRequest } from '../../model/editSweep.model';
import { FlightMeasure } from '../enum/flight-measure.enum';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { RetrieveDetailsWithAircraftResponse } from '../../model/flightDetails.model';
import { SaveSweepRequest } from '../../model/saveSweep.model';
import { SecuritySweep } from '../../model/securitySweep.model';
import { Sweep } from '../../model/sweep.model';
import { SweepDetails } from '../../model/sweepDetails.model';
import { SweepType } from '../enum/sweep-type.enum';
import { UserService } from './user.service';
import { Validation } from './validation';
import { environment } from '../../../environments/environment';

const apiBaseURL: string =
  origin.includes('si') ? environment.idp_si.apiBaseURL :
    (origin.includes('dev') ? environment.idp.apiBaseURL :
      (origin.includes('d2pbaj5agxo0i5') ? environment.idp.apiBaseURL :
        (origin.includes('securitysweep-console.delta.com') ? environment.idp_ssw_prd.apiBaseURL : environment.idp_prd.apiBaseURL)));
// Use for localhost
// const apiBaseURL: string = environment.idp_localhost.apiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class SweepService {
  private readonly SWEEP_SEARCH_URL: string = `${apiBaseURL}/flight/securitySweep/v1/sweep/search`;
  private readonly SWEEP_EDIT_URL: string = `${apiBaseURL}/flight/securitySweep/v1/sweep/edit`;
  private readonly SWEEP_SAVE_URL: string = `${apiBaseURL}/flight/securitySweep/v1/sweep/save`;
  private readonly SWEEP_REPORT_URL: string = `${apiBaseURL}/flight/securitySweep/v1/sweep/report`;
  private readonly HEALTH_CHECK_URL: string = `${apiBaseURL}/flight/securitySweep/v1/health`;
  private saveSweepRequest!: SaveSweepRequest;
  public securitySweepRequestData!: SecuritySweepRequestData;

  private readonly sweepDataSubject$: Subject<SweepFlightData> = new Subject();
  private readonly sswEventDataSubject$: Subject<SweepFlightData> = new Subject();

  //For print
  public sweepConfigListForPrint!: SweepConfigListForPrint[];
  public securitySweepDataForPrint!: SecuritySweep;

  //App and Device ID default
  private appId = "";
  private deviceId = "";
  private canadianFlightMeasure = false;


  //using this model to reset the security sweep values.
  public ResetSweepDetails: Array<SweepDetails> = [
    {
      inspectorTimeStamp: "",
      sweepId: "",
      notes: '',
      typeOfSweep: SweepType.INTERIOR,
      inspectorName: '',
      inspectorId: '',
      crewTeam: '',
      sweepCompletionDateAndTimeStamp: '',
    },
    {
      inspectorTimeStamp: "",
      sweepId: "",
      notes: '',
      typeOfSweep: SweepType.CARGOBINS,
      inspectorName: '',
      inspectorId: '',
      crewTeam: '',
      sweepCompletionDateAndTimeStamp: '',
    },
    {
      inspectorTimeStamp: "",
      sweepId: "",
      notes: '',
      typeOfSweep: SweepType.EXTERIOR,
      inspectorName: '',
      inspectorId: '',
      crewTeam: '',
      sweepCompletionDateAndTimeStamp: '',
    },
  ];

  constructor(
    private readonly http: HttpClient) {


  }

  public healthCheck() {
    const url = this.HEALTH_CHECK_URL;

    return this.http.get(url);
  }

  public search(payload: SecuritySweepRequestData): Observable<Sweep> {
    const url = this.SWEEP_SEARCH_URL;
    // return Observable.th
    if (this.http) {
      return this.http.post<Sweep>(url, payload);
    }
    return throwError(HttpErrorResponse);

  }

  public saveEditSweep(
    payload: EditSweepRequest,
    isPostRequest: boolean
  ): Observable<Sweep> {
    let url = this.SWEEP_EDIT_URL;

    if (isPostRequest) {
      url = this.SWEEP_SAVE_URL;
    }
    return this.http.post<Sweep>(url, payload);
  }

  public getSweepReport(payload: SweepReportRequestData) {
    const url = this.SWEEP_REPORT_URL;

    return this.http.post<Sweep>(url, payload);

    // return ListOfSweeps;
  }

  //mapping the search api resonse to sweep
  public modifyDataStructure(response: any, isSmartTrack: boolean, crewFullNameList: any): SecuritySweep {
    let data: Array<SweepDetails> = JSON.parse(JSON.stringify(this.ResetSweepDetails));
    let latestTimeStamp = new Date("1900-01-01");
    let index = 0;
    for (let i = 0; i < response?.listOfSweeps?.length; i++) {
      let timestamp = new Date(response.listOfSweeps[i].sweepCreationDateAndTimeStamp);
      if (timestamp > latestTimeStamp) {
        latestTimeStamp = timestamp;
        index = i;
      }
    }
    if (
      response?.listOfSweeps?.length &&
      response.listOfSweeps[index].sweepDetails.length
    ) {
      let count = 0;



      data.forEach((sweep: SweepDetails) => {
        let sweepDetail = response.listOfSweeps[index].sweepDetails.find(
          (s: any) => s.typeOfSweep === sweep.typeOfSweep
        );
        //checking crew teams and if its smartrack and setting the crew names list from window event
        if (isSmartTrack && (sweep.typeOfSweep == SweepType.INTERIOR || sweep.typeOfSweep == SweepType.SMARTrack) && sweep.crewTeam == '') {
          sweep.crewTeam = crewFullNameList;
          sweep.notes = 'SmartTrack';
          sweep.editable = isSmartTrack;
          sweep.inspectorId = '';
          sweep.inspectorName = '';
          sweep.inspectorTimeStamp = '';
          sweep.typeOfSweep = SweepType.INTERIOR;
          sweep.locInspectorTimeStamp = moment().format('HH:mm');

        }

        if (sweepDetail) {
          if (sweepDetail.inspectorTimeStamp && sweepDetail.inspectorTimeStamp !== '') {
            const date = moment.utc(sweepDetail.inspectorTimeStamp, 'YYYY-MM-DD HH:mm:ss [GMT]').toDate();
            const localTimeStamp = moment(date).local().format('HH:mm');
            const localDate = moment(date).local().format('YYYY-MM-DD HH:mm:ss');
            sweep.locInspectorTimeStamp = localTimeStamp;
            sweep.inspectorTimeStamp = localDate;

          } else {
            sweep.locInspectorTimeStamp = moment().format('HH:mm');
            sweep.inspectorTimeStamp = moment().format('YYYY-MM-DD HH:mm:ss');
          }
          sweep.sweepId = sweepDetail.sweepId;
          sweep.crewTeam = sweepDetail.crewTeam;
          sweep.notes = sweepDetail.notes;
          sweep.editable = sweepDetail.editable;
          sweep.inspectorId = sweepDetail.inspectorId;
          sweep.inspectorName = sweepDetail.inspectorName;
          sweep.sweepCompletionDateAndTimeStamp = response.listOfSweeps[index].sweepDetails[count].sweepCompletionDateAndTimeStamp;
          count++;
        }
      });
    }

    return { ...response.listOfSweeps[index], ...{ sweepDetails: data } };
  }

  modifyInspectorTimeStamp(response: Sweep) {
    response.listOfSweeps.forEach(sweep => {
      sweep.sweepDetails.forEach(sweepDetail => {
        const date = moment.utc(sweepDetail.inspectorTimeStamp, 'YYYY-MM-DD HH:mm:ss [GMT]').toDate();
        const localTimeStamp = moment(date).local().format('HH:mm');
        const localDate = moment(date).local().format('YYYY-MM-DD HH:mm:ss');
        sweepDetail.locInspectorTimeStamp = localTimeStamp;
        sweepDetail.inspectorTimeStamp = localDate;
      })
    })

    return response;
  }

  /*
  * Consumer app - setSSWSweepDetails JS event
  */
  setSSWSweepDetails(data: SweepFlightData) {
    this.sswEventDataSubject$.next(data);
  }
  public getSSWSweepDetails(): Subject<SweepFlightData> {
    return this.sswEventDataSubject$;
  }

  /*
  * Consumer app - windows message event
  */
  setMessageEvent(data: SweepFlightData) {
    /*
    /* Add leading '0' if the ship number if less than 6 char
    */
    const shipMaxLen = 6;
    this.setAppId(data.applicationID ? data.applicationID : "test");
    this.setDeviceId(data.deviceID ? data.deviceID : "test");
    if (data.shipNumber && data.shipNumber.length < shipMaxLen) {
      const diff = (shipMaxLen - data.shipNumber.length);
      const lead = '0'.repeat(diff);
      data.shipNumber = `${lead}${data.shipNumber}`;
    }

    const sweepFlightData: SweepFlightData = {
      flightNum: data.flightNum,
      faaRegistrationNum: data.faaRegistrationNum ? data.faaRegistrationNum : '',
      shipNumber: data.shipNumber,
      stationCode: data.departureStationCode, //data.stationCode,
      departureDate: data.departureDate,
      departureStationCode: data.departureStationCode,
      flightDestination: data.flightDestination,
      flightOrigin: data.flightOrigin,
      isCanadianVisual: data.isCanadianVisual ? data.isCanadianVisual : false,
      applicationID: data.applicationID,
      deviceID: data.deviceID,
      crewFullNameList: data.crewFullNameList,
      flightMeasure: FlightMeasure.FULL,
      isResweep: data.isResweep,
      mainLine: data.mainLine ? data.mainLine : false,
    }

    this.sweepDataSubject$.next(sweepFlightData);
  }

  public getWindowMessage(): Subject<SweepFlightData> {
    return this.sweepDataSubject$;
  }
  public setSweepConfigListForPrint(sweepConfigList: any) {
    this.sweepConfigListForPrint = sweepConfigList;
  }
  public getSweepConfigListForPrint() {
    return this.sweepConfigListForPrint;
  }

  public setSecuritySweepDataForPrint(securitySweepData: SecuritySweep) {
    this.securitySweepDataForPrint = securitySweepData;
  }
  public getSecuritySweepDataForPrint() {
    return this.securitySweepDataForPrint;
  }
  public clearAll() {
    sessionStorage.clear();
    localStorage.clear();
  }
  public setAppId(id: string) {
    this.appId = id;
  }
  public getAppId() {
    return this.appId;
  }
  public setDeviceId(id: string) {
    this.deviceId = id;
  }
  public getDeviceId() {
    return this.deviceId;
  }
  public getCanadianFlightMeasure() {
    return this.canadianFlightMeasure;
  }
  public setCanadianFlightMeasure(canadianFlightMeasure: boolean) {
    this.canadianFlightMeasure = canadianFlightMeasure
  }
}

