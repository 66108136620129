<!-- Begin page -->


<div *ngIf="!loading" class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body" style="min-height: 500px;">
        <form [formGroup]="sweepFlightForm">
          <div class="flight-details">

            <div class="flight-detatil-info row ">
              <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0 ">


                <div class="info-field  align-items-baseline">

                  <label> {{pageDesc.STATION}} </label>

                  <span *ngIf=isHome class="value"> {{ securitySweepData.stationCode }}</span>
                  <div class="input-container">
                    <input *ngIf=!isHome placeholder="E.g: ATL" type="text" class="form-control rounded-0"
                      formControlName="station" name="station" id="station" onkeydown="return /[a-z]/i.test(event.key)"
                      maxlength="3"
                      (input)="sweepFlightForm.controls['station'].setValue(sweepFlightForm.controls['station'].value.toUpperCase())" />
                    <div
                      *ngIf="sweepFlightForm.controls['station'].invalid && (sweepFlightForm.controls['station'].dirty || sweepFlightForm.controls['station'].touched)">
                      <div class="error-msg" *ngIf="sweepFlightForm.controls['station'].errors?.['required']">
                        <span class="text">{{pageDesc.STATION_REQUIRED}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                <div class="info-field align-items-baseline">
                  <label> {{pageDesc.ACC_FLAG}} </label>

                  <span *ngIf="isHome || !isResweep" class="value"> {{ securitySweepData.faaRegistrationNum }}</span>
                  <div class="input-container">
                    <input *ngIf="!isHome || isResweep" placeholder="E.g: NO5Y89" type="text"
                      class="form-control rounded-0" formControlName="faaRegistrationNum" name="faaRegistrationNum"
                      id="faaRegistrationNum" maxlength="6" onkeydown="return /[[a-zA-Z0-9.]/i.test(event.key)"
                      (input)="sweepFlightForm.controls['faaRegistrationNum'].setValue(sweepFlightForm.controls['faaRegistrationNum'].value.toUpperCase())" />
                    <div
                      *ngIf="sweepFlightForm.controls['faaRegistrationNum'].invalid && (sweepFlightForm.controls['faaRegistrationNum'].dirty || sweepFlightForm.controls['faaRegistrationNum'].touched)">
                      <div class="error-msg"
                        *ngIf="sweepFlightForm.controls['faaRegistrationNum'].errors?.['required']">
                        <span class="text">{{pageDesc.FAA_REQUIRED}}</span>
                      </div>
                      <div class="error-msg"
                        *ngIf="sweepFlightForm.controls['faaRegistrationNum'].errors?.['startsWithError'] || sweepFlightForm.controls['faaRegistrationNum'].errors?.['faaMinLengthError']">
                        <span class="text">{{pageDesc.FAA_INVALID}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                <div class="info-field">
                  <label> {{pageDesc.SHIP}} </label>


                  <span class="value"> {{ securitySweepData.shipNum }}</span>


                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                <div class="info-field">
                  <label> {{pageDesc.DATE}} </label>
                  <span *ngIf="isResweep" class="value"> {{ securitySweepData.departureDate }}</span>
                  <input *ngIf="!isResweep" type="date" class="form-control rounded-0" formControlName="departureDate"
                    [value]="sweepFlightData.departureDate" [max]="maxDate" [min]="minDate" required="" />
                </div>
              </div>
            </div>


            <div class="col-12 mt-3 flight-select-container">
              <div class="flight-select-label">
                {{pageDesc.FLIGHT_SELECT_MSG}}
              </div>
              <div class="radio-container">
                <fieldset [disabled]="isHome">
                  <div class="form-check me-4 w-auto">
                    <input class="form-check-input" type="radio" [value]="!canadianFlightMeasure" id="measure"
                      (click)="showCanadianFlightMeasure('Full')" formControlName="flightMeasure" />
                    <label class="form-check-label" for="measure"> {{pageDesc.FULL}}</label>
                  </div>
                  <div class="form-check w-auto">
                    <input class="form-check-input" type="radio" name="flightMeasure" [value]="canadianFlightMeasure"
                      id="measure1" formControlName="flightMeasure" (click)="showCanadianFlightMeasure('Canadian')" />
                    <label class="form-check-label" for="measure1"> {{pageDesc.CANADIAN_VISUAL}}</label>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="flight-details-input-wrapper" >
            <div class="row">
              <div class="col-12">
                <div class="form-check form-check-checkbox mb-3 form-check-blue">
                  <input class="form-check-input me-0 me-md-2" type="checkbox" (click)="check()" [disabled]="isHome"
                    id="mainLine" [checked]="checkboxEnable" />
                  <label class="form-check-label " for="station"> {{pageDesc.INTERNATIONAL_STATION}}</label>
                </div>
              </div>
            </div>


            <div class="row flight-details-input" *ngIf="showFlightDetails">
              <div class="col-12 d-flex justify-content-between  flex-column flex-md-row ">
                <div class="form-group field mb-3 mb-md-0 justify-content-between align-items-baseline">
                  <label class="col-form-label"> {{pageDesc.OUTBOUND_FLIGHT_NUMBER}}</label>
                  <div class="input-container">
                    <input [disabled]="!checkboxEnable" placeholder="E.g: DL" type="text" class="form-control rounded-0"
                      formControlName="flightNum" id="flightNum" name="flightNum" [readonly]="isHome" maxlength="6"
                      onkeydown="return /[[a-zA-Z0-9.]/i.test(event.key)"
                      (input)="sweepFlightForm.controls['flightNum'].setValue(sweepFlightForm.controls['flightNum'].value.toUpperCase())" />
                    <div
                      *ngIf="sweepFlightForm.controls['flightNum'].invalid && (sweepFlightForm.controls['flightNum'].dirty || sweepFlightForm.controls['flightNum'].touched)">
                      <div class="error-msg" *ngIf="sweepFlightForm.controls['flightNum'].errors?.['required']">
                        <span class="text">{{pageDesc.FLIGHT_NUMBER_REQUIRED}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group field mb-3 mb-md-0 justify-content-between align-items-baseline">
                  <label class="col-form-label flight-origin  col-sm-8 ms-0"> {{pageDesc.FLIGHT_ORIGIN}}</label>
                  <div class="input-container">
                    <input [disabled]="!checkboxEnable" placeholder="E.g: ATL " type="text"
                      class="form-control rounded-0 col-sm-4" formControlName="flightOrigin" name="flightOrigin"
                      [readonly]="isHome" onkeydown="return /[a-z]/i.test(event.key)" maxlength="3"
                      (input)="sweepFlightForm.controls['flightOrigin'].setValue(sweepFlightForm.controls['flightOrigin'].value.toUpperCase())" />
                    <div
                      *ngIf="sweepFlightForm.controls['flightOrigin'].invalid && (sweepFlightForm.controls['flightOrigin'].dirty || sweepFlightForm.controls['flightOrigin'].touched)">
                      <div class="error-msg" *ngIf="sweepFlightForm.controls['flightOrigin'].errors?.['required']">
                        <span class="text">{{pageDesc.ORIGIN_REQUIRED}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group field mb-3 mb-md-0 justify-content-between align-items-baseline">
                  <label class="col-form-label col-sm-8"> {{pageDesc.FLIGHT_DESTINATION}}</label>
                  <div class="input-container">
                    <input [disabled]="!checkboxEnable" placeholder="E.g: MSP" type="text"
                      class="form-control rounded-0 col-sm-4" formControlName="flightDestination" id="flightDestination"
                      name="flightDestination" [readonly]="isHome" onkeydown="return /[a-z]/i.test(event.key)"
                      maxlength="3"
                      (input)="sweepFlightForm.controls['flightDestination'].setValue(sweepFlightForm.controls['flightDestination'].value.toUpperCase())" />
                    <div
                      *ngIf="sweepFlightForm.controls['flightDestination'].invalid && (sweepFlightForm.controls['flightDestination'].dirty || sweepFlightForm.controls['flightDestination'].touched)">
                      <div class="error-msg" *ngIf="sweepFlightForm.controls['flightDestination'].errors?.['required']">
                        <span class="text">{{pageDesc.DESTINATION_REQUIRED}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="action-btn-container text-center">
            <button class="primary-button me-4" [class.disabled]="isResweepDisabled" [disabled]="isResweepDisabled"
              (click)="reset()">
              {{pageDesc.RESWEEP}}
            </button>
            <ng-container>
              <button class="primary-button"
                [class.disabled]="!(sweepFlightForm.controls['flightNum'].valid && sweepFlightForm.controls['station'].valid &&
              sweepFlightForm.controls['faaRegistrationNum'].valid && sweepFlightForm.controls['flightDestination'].valid && sweepFlightForm.controls['flightOrigin'].valid)"
                [disabled]="!(sweepFlightForm.controls['flightNum'].valid && sweepFlightForm.controls['station'].valid &&
              sweepFlightForm.controls['faaRegistrationNum'].valid && sweepFlightForm.controls['flightDestination'].valid && sweepFlightForm.controls['flightOrigin'].valid)"
                (click)="proceed()">
                {{pageDesc.NEXT}}</button>
            </ng-container>


          </div>

          <div class="sweep-details-wrapper mt-4">
            <div class="row w-100">
              <ng-container *ngFor="let item of securitySweepData.sweepDetails let i = index">

                <div class="aircraft-detail interior col-sm-4 col-md-4">

                  <div class="sweep-details" *ngIf="!canadianFlightMeasure">
                    <div class="notes-and-sweep-details bar-fst" *ngIf="i === 0">
                      <div class="sweep-time">
                        <label class="col-form-label">
                          {{ item.typeOfSweep === 'INT' ? 'Aircraft Interior' : (item.typeOfSweep === 'BIN' ? 'Aircraft
                          Baggage / Cargo Bins' : 'Aircraft Exterior') }}
                        </label>
                        <span class="time-input">
                          {{ item.locInspectorTimeStamp}}
                        </span>
                      </div>
                      <ng-container>
                        <div class="notes-container mt-2 ">
                          <label class="col-form-label notes-label"> {{pageDesc.COMPLETED_DATE}}:</label>
                          <div class="notes-wrapper bold">
                            {{ item.inspectorTimeStamp | date : 'YYYY-MM-dd' }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.NOTES}}:</label>
                          <div class="notes-wrapper">
                            {{ item.notes }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.SEARCH_AGENTS}}:</label>
                          <div class="notes-wrapper">

                          </div>
                        </div>

                        <div class="notes-container mt-2">

                          <div class="inspectors">
                            <div class="inspectors-list">
                              <label>
                                {{ item.crewTeam }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                    <div class="notes-and-sweep-details bar" *ngIf="i > 0">
                      <div class="sweep-time">
                        <label class="col-form-label">
                          {{ item.typeOfSweep === 'INT' ? 'Aircraft Interior' : (item.typeOfSweep === 'BIN' ? 'Aircraft
                          Baggage / Cargo Bins' : 'Aircraft Exterior') }}:
                        </label>
                        <span class="time-input">
                          {{ item.locInspectorTimeStamp }}
                        </span>
                      </div>
                      <ng-container>
                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.COMPLETED_DATE}}:</label>
                          <div class="notes-wrapper bold">
                            {{ item.inspectorTimeStamp | date : 'YYYY-MM-dd' }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.NOTES}}:</label>
                          <div class="notes-wrapper">
                            {{ item.notes }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.SEARCH_AGENTS}}:</label>
                          <div class="notes-wrapper">

                          </div>
                        </div>

                        <div class="notes-container mt-2">

                          <div class="inspectors">
                            <div class="inspectors-list">
                              <label>
                                {{ item.crewTeam }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>



                  </div>

                  <div class="sweep-details" *ngIf="canadianFlightMeasure && i ===0">
                    <div class="notes-and-sweep-details">
                      <div class="sweep-time">
                        <label class="col-form-label">
                          {{ item.typeOfSweep === 'INT' ? 'Aircraft Interior' : (item.typeOfSweep === 'BIN' ? 'Aircraft
                          Baggage / Cargo Bins' : 'Aircraft Exterior') }}:
                        </label>
                        <span class="time-input">{{ item.locInspectorTimeStamp }} </span>
                      </div>
                      <ng-container>
                        <div class="notes-container mt-2 ">
                          <label class="col-form-label notes-label"> {{pageDesc.COMPLETED_DATE}}:</label>
                          <div class="notes-wrapper bold">
                            {{ item.inspectorTimeStamp | date : 'YYYY-MM-dd' }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.NOTES}}:</label>
                          <div class="notes-wrapper">
                            {{ item.notes }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label"> {{pageDesc.SEARCH_AGENTS}}:</label>
                          <div class="notes-wrapper">

                          </div>
                        </div>

                        <div class="notes-container mt-2">

                          <div class="inspectors">
                            <div class="inspectors-list">
                              <label>
                                {{ item.crewTeam }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>