import { UserModel } from './../../model/User.model';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  Optional,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { FlightMeasure } from 'src/app/shared/enum/flight-measure.enum';
import { SecuritySweep } from 'src/app/model/securitySweep.model';
import { SweepConfigListForPrint } from 'src/app/model/sweepUI.model';

import { SweepService } from 'src/app/shared/service/sweep.service';
import { UserService } from 'src/app/shared/service/user.service';
import { PageDesc } from '../../shared/enum/sweep-content.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { SweepType } from 'src/app/shared/enum/sweep-type.enum';

export interface DialogData {
  sweepConfigListForPrint: SweepConfigListForPrint[];
  securitySweepDataForPrint: SecuritySweep;
}

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit, AfterViewInit, OnDestroy {
  public checkboxEnable = true;
  public canadianFlightMeasure: boolean = false;
  public pageDesc = PageDesc;

  date = new Date();
  searchCompletionTime: string = '00:00';
  searchCompletionDate: Date | undefined;

  public user!: UserModel;
  public sweepConfigListForPrint!: SweepConfigListForPrint[];
  public securitySweepDataForPrint!: SecuritySweep;
  public readonly SweepType = SweepType;

  private readonly _destroyer: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<PrintComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.sweepConfigListForPrint = this.dialogData.sweepConfigListForPrint;
    this.securitySweepDataForPrint = this.dialogData.securitySweepDataForPrint;
    this.user = this.userService.getUserInformation();

    const dateArray = this.dialogData.sweepConfigListForPrint;
    const latestSweepTimeDate = dateArray.reduce((a, b) => a.inspectorTimeStamp > b.inspectorTimeStamp ? a : b);
    this.searchCompletionDate = new Date(latestSweepTimeDate.inspectorTimeStamp);

    const h = this.searchCompletionDate.getHours().toString();
    const m = this.searchCompletionDate.getMinutes().toString();
    const hh = (`0${this.searchCompletionDate.getHours().toString()}`).slice(-2);
    const mm = (`0${this.searchCompletionDate.getMinutes().toString()}`).slice(-2);
    this.searchCompletionTime = `${hh}:${mm}`;

    this.sweepConfigListForPrint.map((value) => {
      const title = value.typeOfSweep.toLowerCase();
      if (title.includes('int')) {
        value.title = 'Interior Completion Time';
        value.typeOfSweep = SweepType.INTERIOR;
        this.securitySweepDataForPrint.sweepDetails.map((val) => {
          if (val.typeOfSweep == SweepType.INTERIOR) {
            value.inspectorName = val.inspectorName;
          }
        });
      } else if (title.includes('ext')) {
        value.title = 'Exterior Completion Time';
        value.typeOfSweep = SweepType.EXTERIOR;
        this.securitySweepDataForPrint.sweepDetails.map((val) => {
          if (val.typeOfSweep == SweepType.EXTERIOR) {
            value.inspectorName = val.inspectorName;
          }
        });
      } else {
        value.title = 'Cargo / Bin Completion Date';
        value.typeOfSweep = SweepType.CARGOBINS;
        this.securitySweepDataForPrint.sweepDetails.map((val) => {
          if (val.typeOfSweep == SweepType.CARGOBINS) {
            value.inspectorName = val.inspectorName;
          }
        });
      }
    });

    setInterval(() => {
      this.date = new Date();
    }, 100);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      window.print();
    }, 2000);
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }
}
