import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

import { AuthService } from './auth/auth.service';
import { DatePipe } from '@angular/common';
import { FlightMeasure } from './shared/enum/flight-measure.enum';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { SSWInjector } from './shared/service/SSWInjector';
import { SecuritySweep } from './model/securitySweep.model';
import { Subject } from 'rxjs';
import { SweepFlightData } from './model/sweepUI.model';
import { SweepService } from './shared/service/sweep.service';
import { UserModel } from './model/User.model';
import { UserService } from './shared/service/user.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit, OnDestroy {
  title = 'securitysweep';
  isAppLoaded = false;
  // private  userService!: UserService
  public userModel!: UserModel;
  timeTracker: any;
  // public sswInjector!: SSWInjector;
  private readonly sswEventDataSubject$: Subject<SweepFlightData> = new Subject();
  private logoutTimer = environment.inactivityLogoutTime * 60000; // milliseconds
  private appWaitTime = 4000; // milliseconds
  private bufferTime = 1000; // milliseconds;


  constructor(
    private readonly userService: UserService,
    private readonly sweepService: SweepService,
    private readonly authService: AuthService,
    private readonly logger: NGXLogger,
    public router: Router,
    private readonly injector: SSWInjector,
  ) { }

  ngOnInit(): void {
    const userAgent = this.authService.getUserAgent();
    /* const stationList = environment.authorizedStations;
    this.userService.fetchIdTokenInformation().subscribe({
      next: (data) => {
        if (!stationList.includes(data.station)) {
          this.isAppLoaded = true;
          this.authService.noAccessPopupDisplay();
        }
      }, error: (e) => {
      }
    })*/
    if (userAgent === 'desktop') {
      this.startTimeTracker();
    }
    /*
    /* Consumer app setSSWSweepDetails event
    */
    this.injector.listen('setSSWSweepDetails', this.setSSWSweepDetails.bind(this)); //smarttrack
    /*
    /* Windows message listner
    */
    window.addEventListener('message', (event) => {
      this.logger.info('Window Message Received ==> ', event);
      if (event.data.flightNum) {
        this.isAppLoaded = true;
        this.router.navigate(['home']);
        setTimeout(() => {
          this.sweepService.setMessageEvent(event.data); //snap
        }, 1000);
      }
    });
    /*
    /* Inactivity time traker
    */
    this.userService.fetchUserInformation().subscribe((res) => {
      this.userService.assignUser(res);
    });
    window.addEventListener('beforeunload', () => {
      this.sweepService.clearAll();
    })
    /*
    /* Reset Inactivity timer on event
    */
    document.body.addEventListener("mousedown", (e) => {
      if (userAgent === 'desktop') {
        this.resetInactivityTimer();
      }
    });
    document.body.addEventListener("keydown", (e) => {
      if (userAgent === 'desktop') {
        this.resetInactivityTimer();
      }
    });
    document.body.addEventListener("scroll", (e) => {
      if (userAgent === 'desktop') {
        this.resetInactivityTimer();
      }
    });
    document.body.addEventListener("touchstart", (e) => {
      if (userAgent === 'desktop') {
        this.resetInactivityTimer();
      }
    });
    // document.body.addEventListener('mousedown', this.resetInactivityTimer());
    // document.body.addEventListener('mousemove', this.resetInactivityTimer);
    // document.body.addEventListener('touchstart', this.resetInactivityTimer);
    // document.body.addEventListener('keydown', this.resetInactivityTimer);
    // document.body.addEventListener('scroll', this.resetInactivityTimer);

    /*
    /* Wait for JS event and show loader.
    /* Hide loader if no JS event is received from Consumer app.
    */
    setTimeout(() => {
      this.isAppLoaded = true;
    }, 3000);
  }

  setSSWSweepDetails(data: SweepFlightData) { //SMARTTRAC
    this.sweepService.setAppId(data.applicationID ? data.applicationID : "test");
    this.sweepService.setDeviceId(data.deviceID ? data.deviceID : "test");
    const sweepFlightData: SweepFlightData = {
      flightNum: data.flightNum,
      faaRegistrationNum: data.faaRegistrationNum ? data.faaRegistrationNum : '',
      shipNumber: data.shipNumber,
      stationCode: data.departureStationCode,
      departureDate: data.departureDate,
      departureStationCode: data.departureStationCode,
      flightDestination: data.flightDestination,
      flightOrigin: data.flightOrigin,
      isCanadianVisual: data.isCanadianVisual ? data.isCanadianVisual : false,
      applicationID: data.applicationID,
      deviceID: data.deviceID,
      crewFullNameList: data.crewFullNameList,
      flightMeasure: FlightMeasure.FULL,
      isResweep: data.isResweep,
      mainLine: data.mainLine ? data.mainLine : false,
    }
    this.isAppLoaded = true;
    this.router.navigate(['home']);
    setTimeout(() => {
      this.sweepService.setSSWSweepDetails(sweepFlightData);
    }, 1000);
  }

  startTimeTracker() {
    this.timeTracker = setTimeout(() => {
      this.authService.logout();
    }, this.logoutTimer);
  }

  resetInactivityTimer() {
    clearTimeout(this.timeTracker);
    this.startTimeTracker();
  }

  ngOnDestroy(): void {
    sessionStorage.clear();
  }
}
