import { Observable, of, switchMap, timer } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { UserModel } from 'src/app/model/User.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user!: UserModel;

  constructor(
    // private authStorage: OAuthStorage,
    // private readonly logger: NGXLogger,
    private authService: AuthService) {
  }


  public fetchUserInformation(): Observable<UserModel> {
    const userData = this.authService.decodedAccessToken;

    return of(userData);
  }

  public fetchIdTokenInformation(): Observable<UserModel> {
    const userData = this.authService.decodedIDToken;

    return of(userData);
  }

  public assignUser(user: UserModel) {
    this.user = user;
  }

  public getUserInformation(): UserModel {
    return this.user;
  }
}
