import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { OAuthModuleConfig, OAuthStorage } from "angular-oauth2-oidc";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
	constructor(
		private readonly logger: NGXLogger,
		private authStorage: OAuthStorage,
		@Optional() private moduleConfig: OAuthModuleConfig
	) { }
	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;
		if (sendAccessToken) {
			const token = this.authStorage.getItem("access_token");
			const header = "Bearer " + token;
			let headers = req.headers.set("Authorization", header);
			req = req.clone({ headers });
		}

		return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
			if (error.status === 403) {
				this.logger.error(error);
			}
			return throwError(error.error);
		})
		);
	}
}
