import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  TimeInputClickDirective,
  sweepchecklistComponent,
} from './components/sweepchecklist/sweepchecklist.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { BodyComponent } from './components/common/body/body.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationModalComponent } from './components/common/confirmation-modal/confirmation-modal.component';
import { DatePipe } from '@angular/common';
import { HeaderComponent } from './components/common/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { HttpHeaderInterceptor } from './interceptor/http-header.interceptor';
import { IndexComponent } from './components/index/index.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { LogoutModalComponent } from './components/common/logout-modal/logout-modal.component';
import { MaterialExampleModule } from '../material.module';
import { NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { OAuthInterceptor } from './auth/auth-interceptor';
import { PrintComponent } from './components/print/print.component';
import { PublicEventsService } from 'angular-auth-oidc-client';
import { SuccessModalComponent } from './components/common/success-modal/success-modal.component';
import { SweepDetailComponent } from './components/common/sweep-detail/sweep-detail.component';
import { SweepListComponent } from './components/sweep-list/sweep-list.component';
import { TimePipe } from './shared/pipes/time.pipe';
import { ToastsContainerComponent } from './toast/toasts-container/toasts-container.component';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    sweepchecklistComponent,
    HomeComponent,
    HeaderComponent,
    IndexComponent,
    BodyComponent,
    ConfirmationModalComponent,
    SweepDetailComponent,
    SuccessModalComponent,
    LoaderComponent,
    TimePipe,
    ToastsContainerComponent,
    LogoutModalComponent,
    PrintComponent,
    TimeInputClickDirective,
    SweepListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    HttpClientModule,
    MaterialExampleModule,
    NgxWebstorageModule.forRoot(),

    LoggerModule.forRoot({
      level: environment.ngxLogLevel,
      server: NgxLoggerLevel.ERROR,
    } as any),
    AuthModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    PublicEventsService,
    TimePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
