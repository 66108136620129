import { APP_INITIALIZER, NgModule } from "@angular/core";
import {
    AuthConfig,
    OAuthModule,
    OAuthModuleConfig,
    OAuthStorage
} from "angular-oauth2-oidc";

import { AuthService } from "./auth.service";
import { authModuleConfig } from "./auth-module-config";
import { environment } from "src/environments/environment";

const authConfig: AuthConfig =
    origin.includes('si') ? environment.idp_si :
        (origin.includes('dev') ? environment.idp :
            (origin.includes('d2pbaj5agxo0i5') ? environment.idp_si :
                (origin.includes('securitysweep-console.delta.com') ? environment.idp_ssw_prd : environment.idp_prd)));
// Use for localhost
// const authConfig: AuthConfig = environment.idp_localhost;

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
    return sessionStorage;
}

@NgModule({
    imports: [OAuthModule.forRoot()],
    providers: [
        AuthService,
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => () =>
                authService.initAuth(),
            deps: [AuthService],
            multi: true
        }
    ]
})
export class AuthModule { }
