// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  ngxLogLevel: 1,
  appName: 'Delta',
  dateFormat: 'YYYY-MM-DD',
  isHomePageLoaded: false,
  inactivityLogoutTime: 15,
  tsaRoles: 'AMTAOSSSDC_SecuritySweep',
  authorizedStations: [
    'ABE', 'ABR', 'ABY', 'ACK', 'AEX', 'AGS', 'ANC', 'APN', 'ATW', 'AVL',
    'AZO', 'BGM', 'BGR', 'BIL', 'BHM', 'BJI', 'BIS', 'BMI', 'BOS', 'BQK',
    'BRD', 'BTM', 'BTR', 'BTV', 'BUF', 'BUR', 'CDC', 'CID', 'CHA', 'CHO',
    'CIU', 'CHS', 'CMH', 'CNY', 'CPR', 'CVG', 'CRW', 'CSG', 'CWA', 'DAB',
    'DAL', 'DEN', 'DHN', 'DLH', 'DSM', 'ECP', 'EKO', 'ELM', 'ESC', 'EVV',
    'EUG', 'EYW', 'FAI', 'FAR', 'FAT', 'FWA', 'FAY', 'FCA', 'FSD', 'GEG',
    'GFK', 'GNV', 'GPT', 'GRB', 'GTF', 'GTR', 'HHH', 'HIB', 'HLN', 'HPN',
    'ICT', 'IDA', 'ILM', 'IMT', 'INL', 'ITH', 'JAC', 'JAN', 'JAX', 'JNU',
    'KTN', 'LAN', 'LAS', 'LFT', 'LGA', 'LGB', 'LWS', 'MBS', 'MDT', 'MFR',
    'MGM', 'MKE', 'MLI', 'MLU', 'MOT', 'MQT', 'MSN', 'MSO', 'MVY', 'MYR',
    'OAJ', 'OMA', 'ORD', 'ORH', 'PIH', 'PBI', 'PLN', 'PSC', 'PSP', 'PWM',
    'RAP', 'RDM', 'RDU', 'RHI', 'RNO', 'ROA', 'ROC', 'RST', 'RSW', 'SBN',
    'SFO', 'SGF', 'SGU', 'SHV', 'SIT', 'SUN', 'TRI', 'TUL', 'TVC', 'TWF',
    'VLD', 'VPS', 'WYS', 'XNA', 'XWA', 'ATG', 'ATL', 'SNA', 'BLR', 'CLT',
    'SJC', 'ASE', 'EGE', 'HDN', 'HRL', 'MTJ', 'DTW', 'JFK', 'SEA',
  ],
  idp_localhost: {
    apiBaseURL: 'https://ssw-api-dev.delta.com',
    issuer: "https://ssaasi.delta.com",
    clientId: "AMTAOSSSDC_SecuritySweep_OIDC_SI",
    responseType: "code",
    redirectUri: 'http://localhost:4200/',
    scope: "openid read edit profile orginfo",
    useSilentRefresh: true,
    silentRefreshTimeout: 500000, // millisec = 50 sec
    timeoutFactor: 0.7, // call silent refresh when 70% time reached
    sessionChecksEnabled: false,
    showDebugInformation: true,
    clearHashAfterLogin: false,
    skipIssuerCheck: true,
    nonceStateSeparator: "semicolon", // Real semicolon gets mangled by IdentityServer's URI encoding,
    strictDiscoveryDocumentValidation: true,
    silentRefreshRedirectUri:
      window.location.origin + "/silent-refresh.html",
    logoutUrl: "https://ssaasi.delta.com/idp/startSLO.ping?TargetResource=" + window.location.origin + '/',
  },
  idp: {
    apiBaseURL: 'https://ssw-api-dev.delta.com',
    issuer: "https://ssaasi.delta.com",
    clientId: "AMTAOSSSDC_SecuritySweep_OIDC_SI",
    responseType: "code",
    redirectUri: 'https://digitalsweepsheet-dev.delta.com',
    scope: "openid read edit profile orginfo",
    useSilentRefresh: true,
    silentRefreshTimeout: 500000, // millisec = 50 sec
    timeoutFactor: 0.7, // call silent refresh when 70% time reached
    sessionChecksEnabled: false,
    showDebugInformation: true,
    clearHashAfterLogin: false, // Real semicolon gets mangled by IdentityServer's URI encoding,
    silentRefreshRedirectUri:
      window.location.origin + "/silent-refresh.html",
    logoutUrl: "https://ssaasi.delta.com/idp/startSLO.ping?TargetResource=" + window.location.origin + '/',
  },
  idp_si: {
    apiBaseURL: 'https://ssw-api.arpt-mgmt-si.pub.delta.com',
    issuer: "https://ssaasi.delta.com",
    clientId: "AMTAOSSSDC_SecuritySweep_OIDC_SI",
    responseType: "code",
    redirectUri: 'https://securitysweep-console-si.delta.com',
    scope: "openid read edit profile orginfo",
    useSilentRefresh: true,
    silentRefreshTimeout: 500000, // millisec = 50 sec
    timeoutFactor: 0.7, // call silent refresh when 70% time reached
    sessionChecksEnabled: true,
    showDebugInformation: true,
    clearHashAfterLogin: false,
    skipIssuerCheck: true,
    nonceStateSeparator: "semicolon", // Real semicolon gets mangled by IdentityServer's URI encoding,
    strictDiscoveryDocumentValidation: true,
    silentRefreshRedirectUri:
      window.location.origin + "/silent-refresh.html",
    logoutUrl: "https://ssaasi.delta.com/idp/startSLO.ping?TargetResource=" + window.location.origin + '/',
  },
  idp_ssw_prd: {
    apiBaseURL: 'https://ssw-api.delta.com',
    issuer: "https://ssaa.delta.com",
    clientId: "AMTAOSSSDC_SecuritySweep_OIDC",
    responseType: "code",
    redirectUri: 'https://securitysweep-console.delta.com',
    scope: "openid read edit profile orginfo",
    useSilentRefresh: true,
    silentRefreshTimeout: 500000, // millisec = 50 sec
    timeoutFactor: 0.7, // call silent refresh when 70% time reached
    sessionChecksEnabled: true,
    showDebugInformation: true,
    clearHashAfterLogin: false,
    skipIssuerCheck: true,
    nonceStateSeparator: "semicolon", // Real semicolon gets mangled by IdentityServer's URI encoding,
    strictDiscoveryDocumentValidation: true,
    silentRefreshRedirectUri:
      window.location.origin + "/silent-refresh.html",
    logoutUrl: "https://ssaa.delta.com/idp/startSLO.ping?TargetResource=" + window.location.origin + '/',
  },
  idp_prd: {
    apiBaseURL: 'https://ssw-api.delta.com',
    issuer: "https://ssaa.delta.com",
    clientId: "AMTAOSSSDC_SecuritySweep_OIDC",
    responseType: "code",
    redirectUri: 'https://digitalsweepsheet.delta.com',
    scope: "openid read edit profile orginfo",
    useSilentRefresh: true,
    silentRefreshTimeout: 500000, // millisec = 50 sec
    timeoutFactor: 0.7, // call silent refresh when 70% time reached
    sessionChecksEnabled: true,
    showDebugInformation: true,
    clearHashAfterLogin: false,
    skipIssuerCheck: true,
    nonceStateSeparator: "semicolon", // Real semicolon gets mangled by IdentityServer's URI encoding,
    strictDiscoveryDocumentValidation: true,
    silentRefreshRedirectUri:
      window.location.origin + "/silent-refresh.html",
    logoutUrl: "https://ssaa.delta.com/idp/startSLO.ping?TargetResource=" + window.location.origin + '/',
  },

  allowedUrls: ["https://ssw-api.arpt-mgmt-prd.pub.delta.com"]
};
