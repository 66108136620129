import { EditSweepRequest } from 'src/app/model/editSweep.model';
import { FlightLegSeq } from './../../model/editSweep.model';
import { Injectable } from '@angular/core';
import { SaveSweepRequest } from 'src/app/model/saveSweep.model';
import { SecuritySweep } from 'src/app/model/securitySweep.model';
import { SecuritySweepRequestData } from 'src/app/model/sweepUI.model';
import { SweepDetailEditValue } from 'src/app/model/editSweep.model';
import { SweepDetails } from 'src/app/model/sweepDetails.model';
import { UserModel } from 'src/app/model/User.model';
import { UserService } from './user.service';
import { Validation } from './validation';

@Injectable({
  providedIn: 'root',
})
export class SweepRequest {
  flightRegex = '';
  private editSweepRequest!: EditSweepRequest;
  private securitySweepData!: SecuritySweep;
  private flightLegSeq!: FlightLegSeq;

  constructor(private readonly userService: UserService,
    private readonly validation: Validation) { }

  // bind the edit/save APi request
  public buildEditSweepRequest(sweepEditValue: SweepDetailEditValue, sweep: SweepDetails, securitySweepData: SecuritySweep, isResweep: boolean): EditSweepRequest {
    const inspectorDetails: UserModel = this.userService.getUserInformation();

    return this.editSweepRequest = {
      stationCode: securitySweepData.stationCode,
      faaRegistrationNum: securitySweepData.faaRegistrationNum,
      shipNum: securitySweepData.shipNum,
      flightNum: this.validation.getFlightNum(securitySweepData.flightNum),
      airlineCode: (securitySweepData.airlineCode.length > 0) ? securitySweepData.airlineCode : 'DL',
      flightLegCount: '1', //Yet to get the Data from SOA Service
      flightLegSeq: this.getFlightLegSeq(securitySweepData.stationCode), //Yet to get the Data from SOA Service
      flightOrigin: securitySweepData.flightOrigin,
      flightDestination: securitySweepData.flightDestination,
      departureDate: securitySweepData.departureDate,
      cadVisual: securitySweepData.cadVisual,
      mainLine: securitySweepData.mainLine ? securitySweepData.mainLine : false,
      isResweep: isResweep,
      ...securitySweepData.searchId && { searchId: securitySweepData.searchId },
      sweepDetails: [
        {
          ...sweep,
          ...sweepEditValue,
          editable: sweepEditValue.isPostAPI ? false : true,
          //inspectorTimeStamp: moment().format('HH:mm'),
          inspectorId: inspectorDetails.ppr,
          inspectorName: inspectorDetails.given_name + ' ' + inspectorDetails.family_name
          //crewTeam: ''
        },
      ],
    };
  }


  private getFlightLegSeq(stationCode: string) {
    let flightSeqArr: Array<FlightLegSeq> = [];
    this.flightLegSeq = {
      sequence: stationCode
    };
    flightSeqArr.push(this.flightLegSeq);
    return flightSeqArr;

  }

}
