import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './auth/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { IndexComponent } from './components/index/index.component';
import { InvalidParamsComponent } from './components/invalid-params/invalid-params.component';
import { InvalidParamsGuard } from './shared/guards/invalid-params.guard';
import { NgModule } from '@angular/core';
import { PrintComponent } from './components/print/print.component';
import { SweepListComponent } from './components/sweep-list/sweep-list.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { sweepchecklistComponent } from './components/sweepchecklist/sweepchecklist.component';

export const routes: Routes = [

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'sweepchecklist',
    component: sweepchecklistComponent,
    canActivate: [InvalidParamsGuard],
  },
  { path: 'index', component: IndexComponent },
  { path: 'invalid-information', component: InvalidParamsComponent },
  { path: 'sweep-report', component: SweepListComponent },
  { path: '*', redirectTo: 'index' },
  { path: '**', redirectTo: 'index' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
