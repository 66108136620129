import * as moment from 'moment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { FlightMeasure } from '../../../app/shared/enum/flight-measure.enum';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { PageDesc } from '../../shared/enum/sweep-content.enum';
import { PrintComponent } from '../print/print.component';
import { SecuritySweep } from '../../../app/model/securitySweep.model';
import { Sweep } from 'src/app/model/sweep.model';
import { SweepConfigListForPrint } from '../../model/sweepUI.model';
import { SweepDetails } from 'src/app/model/sweepDetails.model';
import { SweepService } from '../../../app/shared/service/sweep.service';
import { SweepType } from '../../../app/shared/enum/sweep-type.enum';
import { SweepTypeTitle } from './../../shared/enum/sweep-type-title.enum';
import { ToastService } from '../../..//app/toast/toast-service';
import { UserModel } from 'src/app/model/User.model';
import { UserService } from './../../shared/service/user.service';

@Component({
  selector: 'app-sweep-list',
  templateUrl: './sweep-list.component.html',
  styleUrls: ['./sweep-list.component.scss'],
})
export class SweepListComponent implements OnInit, OnDestroy {
  public flightMeasure: FlightMeasure = FlightMeasure.FULL;
  public canadianFlightMeasure: boolean = false;
  public pageDesc = PageDesc;
  public readonly SweepType = SweepType;
  public loading: boolean = false;
  public securitySweepData!: SecuritySweep[];
  public datePane: Map<string, string[]> = new Map<string, string[]>();
  public dateArray: Array<string> = new Array;
  public result!: SecuritySweep[];
  public numberOfRecords: number = 0;
  private numberOfDays: Number = 30;
  private readonly _destroyer: Subject<void> = new Subject();
  public station: string = '';
  public sweepConfigList!: SweepConfigListForPrint;
  public selectedShipNum: string = '';


  constructor(
    private readonly sweepService: SweepService,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    private readonly toastService: ToastService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    const today = moment().format("YYYY-MM-DD");
    for (let i = 0; i < 30; i++) {
      this.dateArray.push(moment().subtract(i, 'days').format('MM/DD/YYYY'));
    }

    this.userService.fetchIdTokenInformation().pipe(takeUntil(this._destroyer)).subscribe((user: UserModel) => {
      if (user.station && user.station !== '' && user.station !== null && user.station !== undefined) {
        user.station === 'ATG' ? this.station = 'ATL' : this.station = user.station;
      } else {
        this.station = '';
      }
    })
  }

  public scroll(el: string) {
    const element = document.getElementById(el);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }

  getSweepRecord(element: HTMLElement, d: string) {
    if (!element.className.includes("collapsed")) {
      this.loading = true;
      const date = moment(d).format("YYYY-MM-DD");
      const station = this.station.toUpperCase();
      const payload = this.getSweepRequest(date, station);
      this.result = [];
      this.numberOfRecords = 0;
      this.sweepService.getSweepReport(payload)
        .pipe(takeUntil(this._destroyer))
        .subscribe(
          (res) => {
            this.createSweepReport(res);
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.logger.error('Report  API error response: ', err);
          });
    }
  }

  getSweepRequest(date: string, station: string) {
    const sweepReportRequestData = {
      departureDate: date,
      stationCode: station,
    }
    return sweepReportRequestData;
  }

  createSweepReport(data: Sweep) {
    const sweepData = this.sweepService.modifyInspectorTimeStamp(data);
    this.result = sweepData.listOfSweeps.sort((a, b) => parseFloat(a.shipNum) - parseFloat(b.shipNum));
    this.numberOfRecords = sweepData.listOfSweeps.length;
    this.result.forEach(res => {
      if (res.sweepDetails.length < 3) {
        res.completedSweep = this.getCompletedSweepList(res.sweepDetails);
      }
    });
  }

  private getCompletedSweepList(sweepDetailsArray: SweepDetails[]) {
    let completedSweepList = [];
    for (let y of sweepDetailsArray) {
      switch (y.typeOfSweep) {
        case "BIN":
          completedSweepList.push('B');
          break;
        case "EXT":
          completedSweepList.push('E');
          break;
        case "INT":
          completedSweepList.push('I');
          break;
        default:
          break;
      }
    }
    return completedSweepList;
  }

  printRecord(data: any) {
    this.assignValueToConfig(data, true);
    const sweepConfigListForPrint = this.sweepService.getSweepConfigListForPrint();
    const securitySweepDataForPrint = this.sweepService.getSecuritySweepDataForPrint();
    let dialogRef = this.dialog.open(PrintComponent, {
      width: '100%',
      height: '90%',
      panelClass: 'custom-dialog-container',
      data: {
        sweepConfigListForPrint,
        securitySweepDataForPrint
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  public assignValueToConfig(res: SecuritySweep, isNew: boolean) {
    const data = res.sweepDetails;
    const sweepConfigList: SweepConfigListForPrint[] = data.map((list, index) => {
      return {
        ...list,
        typeOfSweep: null != data[index] ? data[index].typeOfSweep : '',
        inspectorTimeStamp: null != data[index] ? data[index].inspectorTimeStamp : '',
        notes: null != data[index] ? data[index].notes || '' : '',
        crewTeam: (null != data[index] && '' != data[index].crewTeam) ? data[index].crewTeam.split(';') : [],
        editable: (null != data[index] && '' != data[index].crewTeam) ? true : false,
        sweepCompletionDateAndTimeStamp: null != data[index] ? data[index].sweepCompletionDateAndTimeStamp || '' : '',
        inspectorName: null != data[index] ? data[index].inspectorName : '',
      };
    })
    this.sweepService.setSecuritySweepDataForPrint(res);
    this.sweepService.setSweepConfigListForPrint(sweepConfigList);
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  selectedItem(shipNum: string) {
    this.selectedShipNum = shipNum;
  }

}
