<div *ngIf="!loading && !error" class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="d-none d-xl-block card-body">
        <div class="row select-content">
          <div class="col-md-6 col-lg-4 p-0 d-flex flex-column">
            <div class="nav flex-column justify-content-center nav-pills nav-pills-tab" id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <ng-container *ngFor="let item of sweepConfigList">
                <a #anchor class="nav-link mb-1 text-end px-lg-5 py-lg-3 rounded-0 default-cursor" *ngIf="item.show"
                  [ngClass]="{ active: sweepType === item.sweepType}">
                  <div class="d-flex align-items-center">
                    <div class="label" class="int-search-date">
                      <span *ngIf="item.sweepType === 'INT'" class="label">Interior Search Date:</span>
                      <span *ngIf="item.sweepType === 'INT'" class="padding-right">
                        <input type="date" [disabled]="!item.isEdit" class="form-control rounded-0"
                          aria-describedby="date" #date="ngModel" [(ngModel)]="sweepSearchArry.date"
                          id="intEditSweepDate" name="intEditSweepDate" [max]="maxDate" [min]="minDate"
                          required="" /></span>
                      <span class="pointer-cursor" (click)="toggleTab(item)"> {{ item.title }}:</span>
                    </div>
                    <ng-template #elseBlock>
                      <span class="time-input">{{ item.time }}</span>
                    </ng-template>
                    <div *ngIf="item.isEdit; else elseBlock">
                      <ngb-timepicker [(ngModel)]="intTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.INTERIOR }}_time"
                        id="{{ SweepType.INTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'INT'"></ngb-timepicker>
                      <ngb-timepicker [(ngModel)]="extTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.EXTERIOR }}_time"
                        id="{{ SweepType.EXTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'EXT'"></ngb-timepicker>
                      <ngb-timepicker [(ngModel)]="binTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.CARGOBINS }}_time"
                        id="{{ SweepType.CARGOBINS }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'BIN'"></ngb-timepicker>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-3">
              <button class="secondary-button me-4" (click)="backToHome()">
                Back
              </button>
              <button class="secondary-button" [class.disabled]="!isResetEnabled" (click)="reset()">
                Reset
              </button>
            </div>
          </div>

          <div class="col-md-6 col-lg-8 aircrat-panel" style="z-index: 1">
            <div class="tab-content pt-0">
              <ng-container *ngFor="let item of sweepConfigList; let i = index">
                <div class="tab-pane fade" [ngClass]="{
                    'active show': sweepType === item.sweepType
                  }">
                  <app-sweep-detail [sweepDetail]="sweepData[i]" (editSweep)="makeSweepEditable(i)"
                    (confirmEmt)="confirm($event, i)">
                    <ng-container *ngTemplateOutlet="
                        item.sweepType === SweepType.INTERIOR
                          ? interior
                          : item.sweepType === SweepType.EXTERIOR
                          ? exterior
                          : cargo
                      "></ng-container>
                  </app-sweep-detail>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block d-xl-none card-body">
        <div class="row select-content">
          <div class="col-md-6 col-lg-4 p-0 d-flex flex-column">
            <div class="nav flex-column justify-content-center nav-pills nav-pills-tab" id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <ng-container *ngFor="let item of sweepConfigList">
                <a #anchor class="nav-link mb-1 text-end px-lg-5 py-lg-3 rounded-0 default-cursor" *ngIf="item.show"
                  [ngClass]="{
                    active: sweepType === item.sweepType
                  }" (click)="preventDefault($event)">
                  <div class="d-flex align-items-center">
                    <div class="label" style="padding-right: 10px">
                      <span class="pointer-cursor" (click)="toggleTab(item)">{{ item.title }}:</span>
                    </div>
                    <ng-template #elseBlock>
                      <span class="time-input">{{ item.time }}</span>
                    </ng-template>
                    <div *ngIf="item.isEdit; else elseBlock">
                      <ngb-timepicker [(ngModel)]="intTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.INTERIOR }}_time"
                        id="{{ SweepType.INTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'INT'"></ngb-timepicker>
                      <ngb-timepicker [(ngModel)]="extTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.EXTERIOR }}_time"
                        id="{{ SweepType.EXTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'EXT'"></ngb-timepicker>
                      <ngb-timepicker [(ngModel)]="binTime" appTimeInputClick (timeInputClick)="toggleTab(item)"
                        size="small" [meridian]="meridian" name="{{ SweepType.CARGOBINS }}_time"
                        id="{{ SweepType.CARGOBINS }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'BIN'"></ngb-timepicker>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-3">
              <button class="secondary-button me-4" (click)="backToHome()">
                Back
              </button>
              <button class="secondary-button" [class.disabled]="!isResetEnabled" (click)="reset()">
                Reset
              </button>
            </div>
          </div>
          <!-- end col-->
          <div class="col-md-6 col-lg-8 aircrat-panel" style="z-index: 1">
            <div class="tab-content pt-0">
              <ng-container *ngFor="let item of sweepConfigList; let i = index">
                <div class="tab-pane fade" [ngClass]="{
                    'active show': sweepType === item.sweepType
                  }">
                  <app-sweep-detail [sweepDetail]="sweepData[i]" (editSweep)="makeSweepEditable(i)"
                    (confirmEmt)="confirm($event, i)">
                    <ng-container *ngTemplateOutlet="
                        item.sweepType === SweepType.INTERIOR
                          ? interior
                          : item.sweepType === SweepType.EXTERIOR
                          ? exterior
                          : cargo
                      "></ng-container>
                  </app-sweep-detail>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- end col-->
        </div>
      </div>

      <div class="d-md-none card-body">
        <div class="row select-content">
          <div class="col-md-6 col-lg-4 p-0 d-flex flex-column">
            <div class="nav flex-column justify-content-center nav-pills nav-pills-tab" id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <ng-container *ngFor="let item of sweepConfigList; let i = index">
                <a class="nav-link mb-1 text-end px-lg-5 py-lg-3 rounded-0 border-bottom d-flex justify-content-between"
                  *ngIf="item.show" [ngClass]="{
                    active: selectedIndex === i
                  }" (click)="changeTab(item, i)">
                  <div [ngClass]="selectedIndex === i ? 'arrow-active' : 'arrow'"></div>
                  <div class="d-flex align-items-center">
                    <div class="label" style="padding-right: 10px">
                      <span>{{ item.title }}:</span>
                    </div>

                    <ng-template #elseBlock>
                      <span class="time-input">{{ item.time }}</span>
                    </ng-template>
                    <div *ngIf="item.isEdit; else elseBlock">
                      <ngb-timepicker [disabled]="true" [(ngModel)]="intTime" size="small" [meridian]="meridian"
                        name="{{ SweepType.INTERIOR }}_time" id="{{ SweepType.INTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'INT'" [readonlyInputs]="true"
                        [spinners]="false"></ngb-timepicker>
                      <ngb-timepicker [disabled]="true" [(ngModel)]="extTime" size="small" [meridian]="meridian"
                        name="{{ SweepType.EXTERIOR }}_time" id="{{ SweepType.EXTERIOR }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'EXT'" [readonlyInputs]="true"
                        [spinners]="false"></ngb-timepicker>
                      <ngb-timepicker [disabled]="true" [(ngModel)]="binTime" size="small" [meridian]="meridian"
                        name="{{ SweepType.CARGOBINS }}_time" id="{{ SweepType.CARGOBINS }}_time"
                        *ngIf="item.isEdit && item.sweepType === 'BIN'" [readonlyInputs]="true"
                        [spinners]="false"></ngb-timepicker>
                    </div>
                  </div>
                </a>
                <div class="col-md-6 col-lg-8 aircrat-panel px-3 mb-1" style="z-index: 1"
                  *ngIf="sweepType === item.sweepType">
                  <div class="tab-content pt-0">
                    <ng-container *ngFor="let item of sweepConfigList; let i = index">
                      <div class="tab-pane fade" [ngClass]="{
                          'active show': selectedIndex === i
                        }">
                        <app-sweep-detail [sweepDetail]="sweepData[i]" (editSweep)="makeSweepEditable(i)"
                          (confirmEmt)="confirm($event, i)">
                          <ng-container *ngTemplateOutlet="
                              item.sweepType === SweepType.INTERIOR
                                ? interior
                                : item.sweepType === SweepType.EXTERIOR
                                ? exterior
                                : cargo
                            "></ng-container>
                        </app-sweep-detail>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <button class="secondary-button me-4" (click)="backToHome()">
                Back
              </button>
              <button class="secondary-button" [class.disabled]="!isResetEnabled" (click)="reset()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Template -->
<ng-template #cargo>
  <h3 class="title">Aircraft Baggage / Cargo Bins</h3>
  <div class="description">
    {{ pageDesc.CONFIRM_CARGO }}
  </div>
  <div class="description-list">
    <ul>
      <li>Baggage and Cargo Bins</li>
    </ul>
  </div>
</ng-template>

<ng-template #interior>
  <h3 class="title">Aircraft Interior</h3>
  <div class="description">
    {{ pageDesc.CONFIRM_INT }}
  </div>
  <div class="description-list">
    <ul>
      <li>Overhead baggage compartments</li>
      <li>Closets and interior compartments</li>
      <li>Storage bins and other cabin compartments</li>
      <li>Lavatories</li>
      <li>Crew rest areas</li>
      <li>Seats and immediate surrounding area</li>
      <li>Life vests and life vest holders</li>
      <li>Galleys</li>
      <li>Trash receptacles</li>
      <li>Air vents and other accessible compartments</li>
    </ul>
  </div>
</ng-template>

<ng-template #exterior>
  <h3 class="title">Aircraft Exterior</h3>
  <div class="description">
    {{ pageDesc.CONFIRM_EXT }}
  </div>
  <div class="description-list">
    <ul>
      <li>Accessible Service Panels</li>
    </ul>
  </div>
</ng-template>

<app-loader *ngIf="loading"></app-loader>