<div class="row mt-5" *ngIf="!sweepSearchAction">
  <div class="col-lg-4 col-xl-4 mx-auto">
    <div class="card">
      <div class="card-body">
        <form #sweepSearchForm="ngForm" (ngSubmit)="sweepSearchForm.form.valid && sweepSubmit(sweepSearchForm)">
          <div class="form-group row mb-3 field">
            <label for="flightId" class="datefield col-4" style="white-space: nowrap">Ship#:</label>
            <input required type="text" class="form-control col-8" id="flightId" aria-describedby="flight number"
              placeholder="E.g: 002589" #flightId="ngModel" [(ngModel)]="sweepSearchArry.flightId" name="flightId"
              pattern="^[0-9]{1,6}" />
            <div class="error-msg" *ngIf="
                (flightId.touched || sweepSearchForm.submitted) &&
                flightId.errors">
              <span class="text"> {{pageDesc.SHIP_NO_INVALID_MSG}}
              </span>
            </div>
          </div>
          <div class="form-group row mb-3 field">
            <label for="stationCode" class="datefield col-4" style="white-space: nowrap">Station#:</label>
            <input required type="text" class="form-control col-8" id="stationCode" aria-describedby="Station Code"
              placeholder="E.g: ATL" #stationCode="ngModel" [(ngModel)]="sweepSearchArry.stationCode" name="stationCode"
              onkeydown="return /[a-z]/i.test(event.key)" (ngModelChange)="sweepSearchArry.stationCode = sweepSearchArry.stationCode.toUpperCase()"  />
            <div class="error-msg" *ngIf="
                (stationCode.touched || sweepSearchForm.submitted) &&
                stationCode.errors">
              <span class="text"> {{pageDesc.STATION_REQUIRED}}
              </span>
            </div>
          </div>
          <div class="form-group row mb-3 field">
            <label for="date" class="datefield col-4">Depature Date: </label>
            <input required type="date" class="form-control col-8" id="date" aria-describedby="date" #date="ngModel"
              [(ngModel)]="sweepSearchArry.date" name="date" [max]="maxDate" [min]="minDate" />
          </div>
          <div class="d-flex justify-content-center">
            <button *ngIf="!sweepSearchAction" type="submit" class="primary-button">Search</button>
            <button *ngIf="sweepSearchAction" class="primary-button" type="submit">
              <div class="spinner-border" role="status"></div>
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<app-loader *ngIf="sweepSearchAction"></app-loader>