import * as moment from 'moment';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injectable, OnDestroy, OnInit, asNativeElements } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SecuritySweepRequestData, SweepConfigListForPrint } from 'src/app/model/sweepUI.model';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ConfirmationModalComponent } from "../common/confirmation-modal/confirmation-modal.component";
import { FlightMeasure } from 'src/app/shared/enum/flight-measure.enum';
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from '../../shared/enum/sweep-content.enum';
import { SSWInjector } from 'src/app/shared/service/SSWInjector';
import { SecuritySweep } from 'src/app/model/securitySweep.model';
import { SessionStorageService } from 'ngx-webstorage';
import { SweepDetails } from 'src/app/model/sweepDetails.model';
import { SweepFlightData } from 'src/app/model/sweepUI.model';
import { SweepService } from 'src/app/shared/service/sweep.service';
import { SweepType } from 'src/app/shared/enum/sweep-type.enum';
import { ToastService } from 'src/app/toast/toast-service';
import { UserService } from 'src/app/shared/service/user.service';
import { Validation } from 'src/app/shared/service/validation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public minDate = moment().subtract(0, 'days').format('YYYY-MM-DD');
  public maxDate = moment().format('YYYY-MM-DD');
  public checkboxEnable = false;
  public flightMeasure: FlightMeasure = FlightMeasure.FULL;
  public canadianFlightMeasure: boolean = false;
  public pageDesc = PageDesc;
  public sweepFlightForm!: FormGroup;
  public readonly FlightMeasure = FlightMeasure;
  public loading: boolean = true;
  public isResetEnabled: boolean = true;
  public isCandian: boolean = false;
  public AppName!: string | null;
  public mainLine: boolean = false;
  public isHome: boolean = true;
  public isCurrentDt: boolean = false;
  public isFutureDt: boolean = false;
  public isPastDt: boolean = false;
  public sweepFlightData!: SweepFlightData;
  public securitySweepData!: SecuritySweep;
  public securitySweepRequestData!: SecuritySweepRequestData;
  public isCanadianVisual: boolean = false;
  public isCanadianVisualFormInput: boolean = false;
  public isResweepDisabled: boolean = true;
  public isResweep: boolean = false;
  public showFlightDetails: boolean = true;


  public sweepConfigList: Array<{
    title: string;
    time: string;
    notes: string;
    completedDate: string;
    editable: boolean;
    crew: Array<string>;
  }> = [
      {
        title: 'Aircraft Interior',
        time: '',
        notes: '',
        completedDate: '',
        editable: false,
        crew: [],
      },
      {
        title: 'Aircraft Baggage / Cargo Bins',
        time: '',
        notes: '',
        completedDate: '',
        editable: false,
        crew: [],
      },
      {
        title: 'Aircraft Exterior',
        time: '',
        notes: '',
        completedDate: '',
        editable: false,
        crew: [],
      },
    ];

  private readonly _destroyer: Subject<void> = new Subject();
  private paramsHasState = false;
  i: any;
  item: any;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly sweepService: SweepService,
    private readonly validation: Validation,
    private readonly toastService: ToastService,
    private readonly storage: SessionStorageService,
    private readonly injector: SSWInjector,
    private logger: NGXLogger,
    private readonly location: Location,
    private readonly activateRoute: ActivatedRoute,
    private readonly modalService: NgbModal,
  ) {

  }

  ngOnInit(): void {
    // this.location.replaceState('/home');
    this.createForm();
    /* Consumer app setSSWSweepDetails event
    */
    this.sweepService.getSSWSweepDetails().subscribe(data => {
      this.renderHomePage(data);
    })
    /*
    /* Consumer app window message event
    */
    this.sweepService.getWindowMessage().subscribe(data => {
      this.renderHomePage(data);
    })

    if (environment.isHomePageLoaded) {
      if (this.storage.retrieve('sweepFlightDetails')) {
        this.renderHomePage(this.storage.retrieve('sweepFlightDetails'));
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  /*setSSWSweepDetails(args: SweepFlightData) {
    const sweepFlightData: SweepFlightData = {
      flightNum: args.flightNum,
      faaRegistrationNum: args.faaRegistrationNum ? args.faaRegistrationNum : '',
      shipNumber: args.shipNumber,
      departureDate: args.departureDate,
      departureStationCode: args.departureStationCode,
      flightDestination: args.flightDestination,
      flightOrigin: args.flightOrigin,
      isCanadianVisual: args.isCanadianVisual ? args.isCanadianVisual : false,
      applicationID: args.applicationID,
      deviceID: args.deviceID,
      crewFullNameList: args.crewFullNameList,
      flightMeasure: this.flightMeasure
    }
    this.renderHomePage(sweepFlightData);
  }*/

  private renderHomePage(sweepFlightData: SweepFlightData) {
    if (this.storage) {
      this.storage.store('sweepFlightDetails', sweepFlightData);
    }

    this.canadianFlightMeasure = sweepFlightData.isCanadianVisual || false;
    this.sweepFlightData = sweepFlightData;
    environment.isHomePageLoaded = true;
    this.checkboxEnable = sweepFlightData.mainLine || false;
    //this.toggleInputDisable();
    this.isCanadianVisual = this.sweepFlightData.isCanadianVisual || false;
    this.canadianFlightMeasure = this.sweepFlightData.isCanadianVisual || false;
    this.sweepService.setCanadianFlightMeasure(this.canadianFlightMeasure);

    this.fetchData(sweepFlightData);
  }

  public createForm() {
    let formBuilder = this.formBuilder;
    if (!formBuilder) {
      formBuilder = new FormBuilder;
    }
    this.sweepFlightForm = formBuilder.group({
      flightNum: ['', [Validators.required]],
      flightOrigin: ['', [Validators.required]],
      flightDestination: ['', [Validators.required]],
      faaRegistrationNum: ['', [Validators.required, this.validation.firstCharValidator(), this.validation.faaMinLengthValidator()]],
      shipNum: [''],
      departureDate: [''],
      station: ['', [Validators.required]],
      flightMeasure: [true],
      mainLine: [true]
    });
  }
  private toggleInputDisable() {
    if (!this.sweepFlightForm) {
      this.createForm();
    }
    if (this.checkboxEnable) {
      this.sweepFlightForm.get('flightNum')?.enable();
      this.sweepFlightForm.get('flightOrigin')?.enable();
      this.sweepFlightForm.get('flightDestination')?.enable();
    } else {
      this.sweepFlightForm.get('flightNum')?.disable();
      this.sweepFlightForm.get('flightOrigin')?.disable();
      this.sweepFlightForm.get('flightDestination')?.disable();
    }
  }

  public buildFormValue() {
    if (!this.sweepFlightForm) {
      this.createForm();
    }
    this.sweepFlightForm.patchValue({
      flightNum: this.securitySweepData.airlineCode + (this.securitySweepData.flightNum ? this.securitySweepData.flightNum : ''),
      flightOrigin: this.securitySweepData.flightOrigin ? this.securitySweepData.flightOrigin : '',
      flightDestination: this.securitySweepData.flightDestination ? this.securitySweepData.flightDestination : '',
      faaRegistrationNum: this.securitySweepData.faaRegistrationNum ? this.securitySweepData.faaRegistrationNum : '',
      shipNum: this.securitySweepData.shipNum ? this.securitySweepData.shipNum : '',
      departureDate: this.securitySweepData.departureDate ? this.securitySweepData.departureDate : '',
      station: this.securitySweepData.stationCode ? this.securitySweepData.stationCode : '',
      mainLine: this.securitySweepData.mainLine ? this.securitySweepData.mainLine : false,
    });
  }
  public assignValueToConfig(data: Array<SweepDetails>, isNew: boolean) {
    const sweepConfigList: SweepConfigListForPrint[] = this.sweepConfigList.map((list, index) => {
      return {
        ...list,
        typeOfSweep: null != data[index] ? data[index].typeOfSweep : '',
        inspectorTimeStamp: null != data[index] ? data[index].inspectorTimeStamp : '',
        notes: null != data[index] ? data[index].notes || '' : '',
        //crew: (null != data[index] && '' != data[index].crewTeam) ? (isNew ? data[index].crewTeam.split(';') : data[index].crewTeam.split(',')) : [],
        crewTeam: (null != data[index] && '' != data[index].crewTeam) ? data[index].crewTeam.split(';') : [],
        editable: (null != data[index] && '' != data[index].crewTeam) ? true : false,
        sweepCompletionDateAndTimeStamp: null != data[index] ? data[index].sweepCompletionDateAndTimeStamp || '' : '',
        inspectorName: null != data[index] ? data[index].inspectorName : '',
      };
    });
    this.sweepService.setSweepConfigListForPrint(sweepConfigList);
  }

  public check() {
    this.checkboxEnable = !this.checkboxEnable;
    //  this.sweepFlightData.mainLine=this.checkboxEnable;
    // this.toggleInputDisable();
  }

  public proceed() {

    if (this.canadianFlightMeasure) {
      this.sweepFlightData.flightMeasure = FlightMeasure.CANADIAN;
    } else {
      this.sweepFlightData.flightMeasure = FlightMeasure.FULL;
    }

    if (this.isCurrentDt || this.isFutureDt || this.isPastDt) {

      let crewFullNameList = "";
      if (this.sweepFlightData.applicationID == SweepType.INTERIOR || this.sweepFlightData.applicationID == SweepType.SMARTrack) {
        crewFullNameList = this.sweepFlightData.crewFullNameList;
      }

      const notNumber = /^\d+$/.test(this.sweepFlightForm.value.flightNum.substring(0, 2));

      this.sweepFlightData = {
        flightNum: this.sweepFlightForm.value.flightNum,
        faaRegistrationNum: this.sweepFlightForm.value.faaRegistrationNum,
        shipNumber: this.sweepFlightForm.value.shipNum,
        stationCode: this.sweepFlightForm.value.station,
        departureDate: this.sweepFlightForm.value.departureDate,
        departureStationCode: this.sweepFlightForm.value.station,
        flightDestination: this.sweepFlightForm.value.flightDestination,
        flightOrigin: this.sweepFlightForm.value.flightOrigin,
        airlineCode: notNumber ? 'DL' : this.sweepFlightForm.value.flightNum.substring(0, 2),
        isCanadianVisual: this.canadianFlightMeasure,
        mainLine: this.checkboxEnable,
        applicationID: 'INT',
        deviceID: '',
        crewFullNameList: crewFullNameList,
        isResweep: this.isResweep,
        flightMeasure: this.isCanadianVisualFormInput ? (this.sweepFlightForm.value.flightMeasure ? FlightMeasure.FULL : FlightMeasure.CANADIAN) : (this.isCanadianVisual ? FlightMeasure.CANADIAN : FlightMeasure.FULL),
      }
    }

    if (this.storage) {
      this.storage.store('sweepFlightDetails', this.sweepFlightData);
    }
    this.router.navigate(['/sweepchecklist'], {
      state: {
        flightMeasure: this.flightMeasure,

      },

    });
  }

  public showCanadianFlightMeasure(flightMeasure: string) {
    if (flightMeasure == 'Canadian') {
      this.canadianFlightMeasure = true;
    } else {
      this.canadianFlightMeasure = false;
    }
    this.isCanadianVisualFormInput = true;
    this.sweepService.setCanadianFlightMeasure(this.canadianFlightMeasure);

  }

  public fetchData(sweepFlightData: SweepFlightData) {
    let validation = this.validation;
    if (!this.validation) {
      validation = new Validation();
    }
    const flightDateStatus = validation.validateFlightDate(sweepFlightData.departureDate);
    if (flightDateStatus == 'past') {
      this.homeSweepSearch();
      this.isPastDt = true;
    } else if (flightDateStatus == 'current') {
      this.isCurrentDt = true;
      this.homeSweepSearch();
      this.buildDataFromParams();
    } else if (flightDateStatus == 'future') { //+ 1 future date is allowed
      this.isFutureDt = true;
      this.homeSweepSearch();
    } else {
      this.buildDataFromParams();
    }

    return false;
  }


  private homeSweepSearch() {

    if (!this.sweepFlightData.departureDate) {
      return;
    }
    this.securitySweepRequestData = {
      departureDate: this.sweepFlightData.departureDate,
      stationCode: this.sweepFlightData.stationCode,
      shipNum: this.sweepFlightData.shipNumber,
      filtered: true,
    }
    // API call search
    this.sweepService
      .search(this.securitySweepRequestData)
      .pipe(takeUntil(this._destroyer))
      .subscribe(
        (res) => {
          this.mapSearchAPIResponse(res);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.logger.error('Search API error response: ', err);
          if (err.sweepResponseStatusCode == '404') {
            if (this.isCurrentDt || this.isFutureDt || this.isPastDt) {
              this.isHome = false;
            }
            this.buildDataFromParams();
          } else {
            //remove this once vendor is done with testing
            this.buildDataFromParams();
          }
        }
      );

  }

  public mapSearchAPIResponse(res: any) {
    this.securitySweepData = this.sweepService.modifyDataStructure(res, false, '');
    this.sweepService.setSecuritySweepDataForPrint(this.securitySweepData);
    this.securitySweepData.sweepDetails.forEach((sweep) => {
      if (!sweep.inspectorTimeStamp && sweep.notes != '') {

        sweep.inspectorTimeStamp = moment().format('HH:mm');
      }
    });
    if (this.isCanadianVisual) {
      this.isResweepDisabled = this.securitySweepData.sweepDetails.every(sweep => (sweep.typeOfSweep == SweepType.SMARTrack || sweep.typeOfSweep == SweepType.INTERIOR) && sweep.inspectorTimeStamp !== '');
    } else {
      this.isResweepDisabled = !this.securitySweepData.sweepDetails.every(sweep => sweep.inspectorTimeStamp !== '');
    } this.checkResetEnabled();
    //Hide flight details for existing sweep. Show flight details for new sweep. 
    if(this.securitySweepData.mainLine) {
      this.showFlightDetails = true;
    } else {
      this.showFlightDetails = this.securitySweepData.sweepDetails.every(sweep => sweep.inspectorTimeStamp === '');
    }
    this.mapCandianInternationalStationInfo();

    this.assignValueToConfig(
      JSON.parse(JSON.stringify(this.securitySweepData.sweepDetails)), false
    );

    if (this.securitySweepData) {
      this.buildFormValue();

    }
  }

  public buildDataFromParams() {
    let restSweepDetails: Array<SweepDetails> = [];
    let validation = this.validation;
    if (!this.validation) {
      validation = new Validation();
    }
    this.securitySweepData = {
      flightNum: this.sweepFlightData.flightNum ? validation.getFlightNum(this.sweepFlightData.flightNum) || '' : '',
      airlineCode: this.sweepFlightData.flightNum ? this.sweepFlightData.flightNum.substring(0, 2) || '' : '',
      faaRegistrationNum: this.sweepFlightData.faaRegistrationNum ? this.sweepFlightData.faaRegistrationNum : '',
      shipNum: this.sweepFlightData.shipNumber ? this.sweepFlightData.shipNumber || '' : '',
      sweepDetails: this.sweepService ? JSON.parse(JSON.stringify(this.sweepService.ResetSweepDetails)) : restSweepDetails,
      departureDate: this.sweepFlightData.departureDate ? this.sweepFlightData.departureDate || '' : '',
      flightDestination: this.sweepFlightData.flightDestination ? this.sweepFlightData.flightDestination || '' : '',
      flightOrigin: this.sweepFlightData.flightOrigin ? this.sweepFlightData.flightOrigin || '' : '',
      stationCode: this.sweepFlightData.stationCode ? this.sweepFlightData.stationCode || '' : '',
      isResweep: this.isResweep,
      ...this.sweepFlightData.searchId && { searchId: this.sweepFlightData.searchId },
      sweepCreationDateAndTimeStamp: '',
      cadVisual: false,
      mainLine: false,
    }

    this.buildFormValue();
    this.assignValueToConfig(this.mapSweepDetails(this.sweepFlightData.applicationID), true);
  }

  public reset() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.message = PageDesc.Confirmation_MSG_ReSweep;
    modalRef.componentInstance.confirmEvent.subscribe(() => {
      modalRef.componentInstance.disable = false;
      modalRef.close();

      let restSweepDetails: Array<SweepDetails> = [];
      this.securitySweepData.searchId = '';
      this.securitySweepData = {
        ...this.securitySweepData,
        sweepDetails: this.sweepService ? JSON.parse(JSON.stringify(this.sweepService.ResetSweepDetails)) : restSweepDetails,
      };
      let isNew = !this.securitySweepData.searchId;
      this.assignValueToConfig(this.securitySweepData.sweepDetails, isNew);
      this.isResetEnabled = false;
      this.isResweep = true;
      this.showFlightDetails = true;
      this.sweepFlightForm.get('flightNum')?.reset();
      this.sweepFlightForm.get('flightOrigin')?.reset();
      this.sweepFlightForm.get('flightDestination')?.reset();
      this.sweepFlightForm.get('faaRegistrationNum')?.reset();
      this.sweepFlightForm.get('station')?.reset();
      this.isHome = false;
    })

  }

  public checkResetEnabled() {
    const sweepDetails = this.securitySweepData.sweepDetails;
    this.isResetEnabled = !!(
      sweepDetails[0].notes ||
      sweepDetails[0].crewTeam ||
      sweepDetails[1].notes ||
      sweepDetails[1].crewTeam ||
      sweepDetails[2].notes ||
      sweepDetails[2].crewTeam
    );
  }

  public mapCandianInternationalStationInfo() {
    if (this.securitySweepData.cadVisual) {
      this.isCandian = true;
      this.canadianFlightMeasure = true;
      this.flightMeasure = FlightMeasure.CANADIAN;
    }

  }

  public mapSweepDetails(sweepType: any) {

    return [
      // {
      //   sweepCompleteLocalTimeStamp: '',
      //   notes: '',
      //   typeOfSweep: SweepType.INTERIOR,
      //   inspectorTimeStamp: '',
      //   inspectorName: '',
      //   inspectorId: '',
      //   crewTeam:sweepType == SweepType.INTERIOR ? this.sweepFlightData.crewFullNameList : '',
      // },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.SMARTrack,
        inspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: sweepType == SweepType.SMARTrack ? this.sweepFlightData.crewFullNameList : '',
      },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.CARGOBINS,
        inspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: sweepType == SweepType.CARGOBINS ? this.sweepFlightData.crewFullNameList : '',
      },
      {
        sweepCompleteLocalTimeStamp: '',
        notes: '',
        typeOfSweep: SweepType.EXTERIOR,
        inspectorTimeStamp: '',
        inspectorName: '',
        inspectorId: '',
        crewTeam: sweepType == SweepType.EXTERIOR ? this.sweepFlightData.crewFullNameList : '',
      },


    ]

  }


}
