import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageDesc } from './../../../shared/enum/sweep-content.enum';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() message = PageDesc.Confirmation_MSG;

  @Input() disable: boolean = false;

  @Output() confirmEvent: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  confirm() {
    if (this.disable) {
      return;
    }
    this.confirmEvent.emit();
  }

  closeModal() {
    this.activeModal.close();
  }
}
