import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/toast/toast-service';

@Injectable({
  providedIn: 'root',
})
export class InvalidParamsGuard implements CanActivate {
  constructor(private readonly router: Router,
    private toastService: ToastService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    /*const queryParams = route.queryParamMap;
    const flightNum = queryParams.get('flightNum');
    const departureDate = queryParams.get('departureDate');
    const faaRegistrationNum = queryParams.get('FAARegistrationNumber');
    const shipNum = queryParams.get('shipNumber');
    const internationalMainlineStation = queryParams.get('InternationalMainlineStation');
    const isCanadianVisual = queryParams.get('IsCanadianVisual');
    const deviceid = queryParams.get('deviceid');
    const applicationid = queryParams.get('applicationid');
    const appName = queryParams.get('AppName');
    const FlightOrigin = queryParams.get('Flightorigin');
    const FlightDestination = queryParams.get('FlightDestination');*/

    return true;
  }




}

