import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from 'src/app/auth/auth.service';
import { LogoutModalComponent } from '../logout-modal/logout-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintComponent } from '../../print/print.component';
import { Router } from '@angular/router';
import { SweepConfigListForPrint } from '../../../model/sweepUI.model';
import { SweepService } from '../../../shared/service/sweep.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //For print
  sweepConfigListForPrint!: SweepConfigListForPrint[];
  isPrint = false;
  hideHeaderIcons = false;
  hideText = false;
  showText = true;
  showReport = true;
  constructor(
    private readonly authService: AuthService,
    private readonly sweepService: SweepService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    let route = this.router;
    const rolesArr = this.authService.decodedAccessToken.roles;
    if (!route) {
      route: Router;
    }
    this.isPrint = route.url.includes('print');
    this.hideHeaderIcons = route.url.includes('sweep-report');
    this.hideText = route.url.includes('sweep-report');
    this.showText = route.url.includes('sweep-report');

  }

  logout(): void {
    const modalRef = this.modalService.open(LogoutModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.conformLogout.subscribe(() => {
      modalRef.componentInstance.disable = true;
      this.authService.logout();
      modalRef.componentInstance.disable = false;
      modalRef.close();
    });

  }


  print(): void {

  }

  @ViewChild("printElement") printElement!: ElementRef;
  @ViewChild("sweepListElement") sweepListElement!: ElementRef;

  public triggerPrint(): void {


    const sweepConfigListForPrint = this.sweepService.getSweepConfigListForPrint();
    const securitySweepDataForPrint = this.sweepService.getSecuritySweepDataForPrint();
    if (this.dialog) {
      let dialogRef = this.dialog.open(PrintComponent, {
        width: '100%',
        height: '90%',
        panelClass: 'custom-dialog-container',
        data: {
          sweepConfigListForPrint,
          securitySweepDataForPrint
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  triggerSweepReport() {
    this.router.navigate(['sweep-report'], {
    });
  }
}
