import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  constructor() {}

  transform(value: string): string {
    if(!value){
      return '';
    }
    return moment(value, 'HH:mm').format('HH:MM ');
  }

}
