import * as moment from 'moment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SecuritySweepRequestData, SweepFlightData } from 'src/app/model/sweepUI.model';
import { Subject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { FlightMeasure } from 'src/app/shared/enum/flight-measure.enum';
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { NgForm } from '@angular/forms';
import { PageDesc } from 'src/app/shared/enum/sweep-content.enum';
import { RetrieveDetailsWithAircraftResponse } from '../../model/flightDetails.model';
import { Router } from '@angular/router';
import { SaveSweepRequest } from 'src/app/model/saveSweep.model';
import { SessionStorageService } from 'ngx-webstorage';
import { Sweep } from 'src/app/model/sweep.model';
import { SweepService } from '../../shared/service/sweep.service';
import { SweepType } from 'src/app/shared/enum/sweep-type.enum';
import { ToastService } from '../../toast/toast-service';
import { UserModel } from 'src/app/model/User.model';
import { UserService } from 'src/app/shared/service/user.service';
import { Validation } from './../../shared/service/validation';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, OnDestroy {
  public pageDesc = PageDesc;
  currentDate = moment().format('YYYY-MM-DD');
  minDate = moment().subtract(2, 'days').format('YYYY-MM-DD');
  maxDate = moment().add(1, 'days').format('YYYY-MM-DD');
  flightId: string = '';
  sweepSearchAction: boolean = false;
  private readonly _destroyer: Subject<void> = new Subject();

  flightRegex = '(/^\d+$/)';
  public hasValidationError: boolean = false;
  sweepSearchArry: any = {};
  public securitySweepData!: Sweep;
  public securitySweepRequestData!: SecuritySweepRequestData;
  private saveSweepRequest!: SaveSweepRequest;
  public isCurrentDt: boolean = false;
  public isFutureDt: boolean = false;
  public isPastDt: boolean = false;


  constructor(
    private readonly router: Router,
    private readonly sweepService: SweepService,
    private toastService: ToastService,
    private authService: AuthService,
    private readonly userService: UserService,
    private readonly validation: Validation,
    private readonly logger: NGXLogger,
    private readonly location: Location,
    private readonly storage: SessionStorageService,
  ) { }
  ngOnInit(): void {
    this.sweepSearchArry = { date: this.currentDate };
    this.userService.fetchIdTokenInformation().pipe(takeUntil(this._destroyer)).subscribe((user: UserModel) => {
      if (user.station && user.station !== '' && user.station !== null && user.station !== undefined) {
        user.station === 'ATG' ? this.sweepSearchArry.stationCode = 'ATL' : this.sweepSearchArry.stationCode = user.station;
      } else {
        this.sweepSearchArry.stationCode = '';
      }
    })
  }

  ngOnDestroy(): void {
    this._destroyer.next();
    this._destroyer.complete();
  }

  private navigate() {

    this.router.navigate(['home'], {
    });
  }

  mapSweepFlightData(data: any) {
    const sweepFlightData: SweepFlightData = {
      flightNum: data.flightNum,
      faaRegistrationNum: data.faaRegistrationNum ? data.faaRegistrationNum : '',
      shipNumber: data.shipNum,
      departureDate: data.departureDate,
      departureStationCode: data.stationCode,
      stationCode: data.stationCode,
      flightDestination: data.flightDestination,
      flightOrigin: data.flightOrigin,
      isCanadianVisual: data.cadVisual ? data.cadVisual : false,
      applicationID: data.sweepDetails[0].typeOfSweep,
      // deviceID: data.deviceID,
      crewFullNameList: data.sweepDetails[0].crewTeam,
      flightMeasure: FlightMeasure.FULL,
      deviceID: '',
      isResweep: data.isResweep,
      mainLine: data.mainLine ? data.mainLine : false,
    };
    environment.isHomePageLoaded = true;
    if (this.storage) {
      this.storage.store('sweepFlightDetails', sweepFlightData);
    }

  }

  sweepSubmit(f: NgForm) {
    this.sweepSearchAction = true;
    let shipNumber = f.value.flightId;
    let departureDate = f.value.date;
    let stationCode = f.value.stationCode;
    this.ValidateFormRequest(shipNumber, departureDate, stationCode);


  }

  public ValidateFormRequest(shipNumber: any, departureDate: any, stationCode: any) {
    let validation = this.validation;
    if (!this.validation) {
      validation = new Validation();
    }
    let isShipNo = validation.validateShipNumber(shipNumber);
    if (isShipNo) {
      this.toastService.show(PageDesc.SHIP_NO_INVALID_MSG, {
        classname: 'bg-danger text-light',
        delay: 1000,
      });
    } else {
      if (!isShipNo && validation.validateNumeric(shipNumber)) {
        shipNumber = ("000000" + shipNumber).slice(-6);
      }
      const flightDateStatus = validation.validateFlightDate(departureDate);
      if (flightDateStatus == 'past') {
        this.isPastDt = true;
        this.indexSweepSearch(departureDate, shipNumber, stationCode);
      } else if (flightDateStatus == 'current') {
        this.isCurrentDt = true;
        this.indexSweepSearch(departureDate, shipNumber, stationCode);
      } else if (flightDateStatus == 'future') { // Current + 1 day future is allowed
        this.isFutureDt = true;
        this.indexSweepSearch(departureDate, shipNumber, stationCode);
      }
    }
  }

  public indexSweepSearch(departureDate: any, shipNum: string, stationCode: string) {
    const params = new HttpParams().set('departureDate', departureDate).set('shipNum', shipNum).set('stationCode', stationCode);
    this.securitySweepRequestData = {
      departureDate: departureDate,
      shipNum: shipNum,
      stationCode: stationCode,
      filtered: true,
    }

    // params.set('flightNum', flightNum);

    this.sweepService
      .search(this.securitySweepRequestData)
      .pipe(takeUntil(this._destroyer))
      .subscribe(
        (res) => {
          this.sweepSearchAction = false;
          const data = res.listOfSweeps[0];
          this.mapSweepFlightData(data);
          this.navigate();
        },
        (err) => {
          this.sweepSearchAction = false;
          if (err.sweepResponseStatusCode == '404') {
            if (this.isCurrentDt || this.isFutureDt || this.isPastDt) {
              this.mapCurrentDtSweepFlightData();
              this.navigate();
            }
          }
          // this.logger.error(err);
          /* this.toastService.show(err.sweepMessage,  {
             classname: 'bg-danger text-light',
             delay: 8000,
           });*/
        }
      );


  }

  mapCurrentDtSweepFlightData() {
    const sweepFlightData: SweepFlightData = {
      flightNum: '',
      faaRegistrationNum: '',
      shipNumber: this.securitySweepRequestData.shipNum,
      departureDate: this.securitySweepRequestData.departureDate,
      stationCode: this.securitySweepRequestData.stationCode,
      departureStationCode: '',
      flightDestination: '',
      flightOrigin: '',
      isCanadianVisual: false,
      applicationID: 'INT',
      // deviceID: data.deviceID,
      crewFullNameList: '',
      flightMeasure: FlightMeasure.FULL,
      deviceID: '',
      isResweep: false,
      mainLine: false
    };
    environment.isHomePageLoaded = true;
    if (this.storage) {
      this.storage.store('sweepFlightDetails', sweepFlightData);
    }
  }

  showSuccess() {
    this.toastService = new ToastService;

    this.toastService.show('I am a success toast', {
      classname: 'bg-success text-light',
      delay: 10000,
    });
  }

  logout(): void {
    if (this.authService) {
      this.authService.logout();
    }
  }
}
