import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router
} from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

/**
 * If the access token has expired or is invalid, then routes should
 * not be accessible. This Guard protects the configured routes and
 * makes sure that the access token is still valid.
 */
@Injectable({
	providedIn: "root"
})
export class AuthenticationGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router
		) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {

		if (!this.authService.hasValidToken()) {
			window.location.href = window.location.origin;
		}
		return true;
	}
}
