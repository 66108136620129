import * as moment from 'moment';

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Injectable } from '@angular/core';
import { PageDesc } from '../../shared/enum/sweep-content.enum';
import { SecuritySweepRequestData } from 'src/app/model/sweepUI.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Validation {
  flightRegex = '';

  constructor() { }



  //Validate the null and empty value in the request
  public validateEmptyValues(sweepData: any) {
    let isValid = true;
    if (null == sweepData || sweepData == '') {
      isValid = false;
    }
    return isValid;
  }

  // validating ship number
  public validateShipNo(shipNumber: any) {
    let isShipNo = false;
    if (shipNumber != null && (shipNumber.length > 6 || !this.validateNumeric(shipNumber))) {
      isShipNo = true;
    } else if (null == shipNumber) {
      isShipNo = true;
    }
    return isShipNo;
  }
  // validating apha numeric
  public ValidateAlphaNumeric(value: string) {
    let pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-z0-9]+)$');
    return pattern.test(value);

  }
  // validating numeric
  public validateNumeric(value: any) {
    let pattern = new RegExp(/^\d+$/);
    return pattern.test(value);
  }

  //validating request parameters  and binding error messages
  public validateShipNumber(shipNumber: string) {


    //work in progress
    let isShipNo = false;
    let isValidShipNo = this.validateEmptyValues(shipNumber);
    if (isValidShipNo) {
      isShipNo = this.validateShipNo(shipNumber);

    }
    return isShipNo;
  }
  public validateFlightDate(flightDate: string): string {
    // return 'current', 'past' or 'future (only 1 day is allowed)'
    let flightDateStatus = '';
    let flightDt = moment(flightDate, environment.dateFormat);
    if (flightDt.isSame(moment().format(environment.dateFormat)))
      flightDateStatus = 'current';
    else if (flightDt.isBefore(moment().format(environment.dateFormat)))
      flightDateStatus = 'past';
    else if (flightDt.isAfter(moment().format(environment.dateFormat)) ||
      flightDt.isAfter(moment().add(1, 'days').format(environment.dateFormat))) // Today + 1 future day is allowed
      flightDateStatus = 'future';

    return flightDateStatus;
  }
  public getFlightNum(flightNum: string) {
    var numChar = flightNum.match(/(\d+)/) || '';
    var flightNumberValue = numChar[0] ? +numChar[0] : '';
    return flightNumberValue + '';
  }

  public validateAllLeters(value: string) {
    var letters = /^[A-Za-z]+$/;
    if (value.match(letters))
      return true;

    return false;
  }

  firstCharValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      const startsWith = /^N/.test(value);
      // const passwordValid = startsWith;
      return !startsWith ? { startsWithError: true } : null;
    }
  }

  faaMinLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      const faaLength = value.length;
      // const passwordValid = startsWith;
      return faaLength < 4 ? { faaMinLengthError: true } : null;
    }
  }
}

