<div class="modal-header">
  <span class="modal-title">Logout Confirmation</span>
  <span class="close-btn" (click)="closeModal()">
    <i class="icon ion-md-close"></i>
  </span>
</div>
<div class="modal-body">
  <div class="content">
    <div class="content-body text-center">
      <p>
        {{ message }}
      </p>
    </div>

    <div class="action-btns">

      <button
      class="primary-button"
      type="button"
      (click)="closeModal()"
    >
      No

    </button>


      <button
        class="primary-button"
        type="button"
        (click)="confirm()"
      >
        Yes

      </button>


    </div>
  </div>
</div>

