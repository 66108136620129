import {
  AuthConfig,
  LoginOptions,
  NullValidationHandler,
  OAuthErrorEvent,
  OAuthInfoEvent,
  OAuthService,
  OAuthStorage,
} from "angular-oauth2-oidc";
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';

import { AccessModalComponent } from "../components/common/access-modal/access-modal.component";
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { SweepService } from "../shared/service/sweep.service";
import { authCodeFlowConfig } from '../auth-code-flow.config';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private jwtHelper: JwtHelperService = new JwtHelperService();

  private _decodedAccessToken: any;

  private _decodedIDToken: any;

  private decreaseExpirationBySec = 0;

  get decodedAccessToken(): any {
    return this._decodedAccessToken;
  }
  get decodedIDToken(): any {
    return this._decodedIDToken;
  }

  constructor(
    private readonly location: Location,
    private oauthService: OAuthService,
    //private eventService: PublicEventsService,
    private authConfig: AuthConfig,
    public router: Router,
    private sweepService: SweepService,
    private readonly logger: NGXLogger,
    private readonly modalService: NgbModal,
    private readonly storage: OAuthStorage,
  ) { }

  private handleToken(): void {
    this._decodedAccessToken = this.decodeToken(this.oauthService.getAccessToken());

    this._decodedIDToken = this.decodeToken(this.oauthService.getIdToken());
  }
  public logout(): void {
    this.oauthService.logOut();
  }
  public refresh(): void {
    this.oauthService.silentRefresh();
  }
  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // Useful methods
  /*public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }
  public get idToken(): string {
    return this.oauthService.getIdToken();
  }
  public get refreshToken(): string {
    return this.oauthService.getRefreshToken();
  }*/

  public get identityClaims(): any {
    return this.oauthService.getIdentityClaims();
  }

  private decodeToken(token: string) {
    let payload = '';
    if (token && token !== '' && token !== undefined) {
      payload = JSON.parse(atob(token.split('.')[1]));

      return payload;
    }
    else {
      payload = '';
      return payload;
    }

  }

  private getClockSkewInMsec(defaultSkewMsc = 600_000) {
    if (!this.authConfig.clockSkewInSec && this.authConfig.clockSkewInSec !== 0) {
      return defaultSkewMsc;
    }
    return this.authConfig.clockSkewInSec * 1000;
  }

  private hasValidAccessToken(): boolean {
    if (this.oauthService.getAccessToken()) {
      this.handleToken();
      // const accessToken = this.decodeToken(this.oauthService.getAccessToken())
      const expiresAtToken = this.decodedAccessToken.exp;
      const nowToken = this.decodedAccessToken.iat;
      // this.storage.setItem('expires_at', expiresAtToken); //exp
      // const now = this.dateTimeService.new(); //iat
      if (
        expiresAtToken &&
        parseInt(expiresAtToken, 10) - this.decreaseExpirationBySec <
        parseInt(nowToken, 10) - this.decreaseExpirationBySec
      ) {
        return false;
      }

      return true;
    }

    return false;
  }

  private loadDiscoveryDocumentAndLogin(
  ): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.hasValidAccessToken()) {
        //const state = typeof options.state === 'string' ? options.state : '';
        this.oauthService.initLoginFlow();
        return false;
      } else {
        return true;
      }
    });
  }

  async initAuth(): Promise<any> {
    /*this.sweepService.healthCheck().subscribe(() => {
      this.logger.info('Health Check URL was called');
    })*/
    return new Promise<void>((resolveFn, rejectFn) => {
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(sessionStorage);
      this.oauthService.tokenValidationHandler =
        new NullValidationHandler();

      // All Events
      this.oauthService.events.subscribe(event => {
        const userAgent = this.getUserAgent();
        if (event instanceof OAuthErrorEvent) {
          this.logger.error('Oauth Service Error Event: ', event.type, event.reason);
          //Log the user out on OAuthErrorEvent
          this.logout();
        } else if (event instanceof OAuthInfoEvent) {
          this.logger.info('Oauth Service Event Received: ', event);
          if (event.type === 'token_expires' && event.info === 'access_token') {
            //Logout user when token expires
            if (userAgent !== 'desktop') {
              this.logout();
            }
          }
        }
      });

      // Token Events
      this.oauthService.events
        .pipe(filter((e: any) => e.type === "token_received"))
        .subscribe(({ type }) => {
          this.handleToken();
        });

      this.loadDiscoveryDocumentAndLogin().then(
        (isLoggedIn) => {
          const userAgent = this.getUserAgent();
          this.sweepService.setAppId(userAgent);
          this.sweepService.setDeviceId(userAgent);
          this.location.replaceState('/index');
          this.handleToken();
          if (isLoggedIn) {
            const state = this.oauthService.state;
            if (userAgent === 'desktop') {
              this.oauthService.setupAutomaticSilentRefresh();
            }
            if (state !== "" && state !== undefined) {
              const url = new URL(decodeURIComponent(state));
              const params = new URLSearchParams(url.search);
              this.router.navigate([url.pathname]);
              this.router.navigate(['/index']);
            }
            resolveFn();
          } else {
            this.oauthService.initImplicitFlow(
              window.location.href
            );
            rejectFn();
          }
        },
        (error) => {
          if (error.status === 400) {
            location.reload();
          }
        }
      );
    });
  }

  getUserAgent(): string {
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    //Mozilla/5.0 (Linux; Android 8.0.0; SM-G955U Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.141 Mobile Safari/537.36 Edg/105.0.0.0
    //Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1 Edg/105.0.0.0
    if (/SM|SAMSUNG|Chrome87.0.4280.141/i.test(userAgent)) {
      return 'mobile'
    }
    //Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36 Edg/105.0.1343.53
    if (/Chrome|Mozilla|AppleWebKit|Safari|Edg/i.test(userAgent)) {
      return 'desktop'
    } else {
      return 'desktop'
    }
  }

  noAccessPopupDisplay(): void {
    const modalRef = this.modalService.open(AccessModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.confirmEventAccessModal.subscribe(() => {
      modalRef.componentInstance.disable = true;
      this.logout();
      modalRef.componentInstance.disable = false;
      modalRef.close();
    });

  }
}
