<!-- Begin page -->
<div *ngIf="!loading" class="record">
  <span class="station">
    <label>Station: </label>
    <input required type="text" class="form-control" id="station" aria-describedby="station" [(ngModel)]="station"
      name="station" />
  </span>
  <span>{{numberOfRecords}} Records</span>
</div>
<div class="rec_count"></div>
<div></div>
<div style="width:10%; float:left; margin-bottom: 10px;">
  <!-- Example single danger button -->
  <div class="accordion" id="accordionExample">
    <ng-container *ngFor="let i = index; let date of dateArray; ">
      <div class="accordion-item">
        <h2 class="accordion-header" id="i">
          <button #accord class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" (click)="getSweepRecord(accord, date)">
            {{date}}
          </button>
        </h2>
        <div id="{{'collapse'+i}}" class="accordion-collapse collapse " aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">

          <div>
            <ng-container *ngFor="let i = index; let item of result; ">
              <ul class="accordion_list">
                <li (click)="selectedItem(item.shipNum)" [ngClass]="{ 'selected' : item.shipNum === selectedShipNum }"
                  style="padding: 6px 10px 2px 30px;">
                  <label *ngIf="item.cadVisual || item.sweepDetails.length > 2" (click)="scroll(item.shipNum)"
                    style="font-size: 16px; font-weight: bold;">
                    <svg width="17" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM5 8.5L2.5 6L3.205 5.295L5 7.085L8.795 3.29L9.5 4L5 8.5Z"
                        fill="#215E23" />
                    </svg>&nbsp;&nbsp; {{item.shipNum}} </label>

                  <label *ngIf="!item.cadVisual && item.sweepDetails.length < 3" (click)="scroll(item.shipNum)"
                    style="font-size: 16px; color: rgb(134, 134, 134);">
                    <svg width="17" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM6.5 8.5H5.5V7.5H6.5V8.5ZM6.5 6.5H5.5V3.5H6.5V6.5Z"
                        fill="#DB7F00" />
                    </svg>&nbsp;&nbsp; {{item.shipNum}} &nbsp;

                    <span *ngFor="let indx = index; let i of item?.completedSweep">
                      <span>{{i}}<span *ngIf="indx !== item.sweepDetails.length - 1">, </span></span>
                    </span>
                  </label>

                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div id="testid" style="width:90%; float:right;">
  <ng-container *ngIf="numberOfRecords === 0">
    <div class="float">No Record Found</div>
  </ng-container>
  <ng-container *ngFor="let sweepData of result; let i = index">
    <div class="row" id="{{ sweepData.shipNum }}">
      <div class="col-12">
        <div class="card">
          <div class="card-body" style="min-height: 500px">
            <div class="flight-details">
              <div class="top-section">
                <div class="record-section">
                  <label>Record {{ i + 1 }}:</label>
                  <!--<span class="value"> {{ sweepData.recordName }}</span>-->
                </div>

                <div class="date-section">
                  <!--<div class="address me-4">ATL - Atlanta, GA</div>-->
                  <div class="date me-4">
                    {{
                    sweepData.sweepCreationDateAndTimeStamp
                    | date : "mediumDate"
                    }}
                  </div>

                  <div class="time">
                    {{
                    sweepData.sweepCreationDateAndTimeStamp | date : "HH:mm"
                    }}
                  </div>
                </div>
              </div>
              <div class="print-section row">
                <div class="print-icon">
                  <a (click)="printRecord(sweepData)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.5999 8.00001C13.5999 7.55818 13.958 7.20001 14.3999 7.20001H16.7999C17.2417 7.20001 17.5999 7.55818 17.5999 8.00001C17.5999 8.44184 17.2417 8.80001 16.7999 8.80001H14.3999C13.958 8.80001 13.5999 8.44184 13.5999 8.00001Z"
                        fill="#006FDF" />
                      <path
                        d="M7.81843 15.0909C7.36656 15.0909 7.00024 15.4572 7.00024 15.9091C7.00024 16.361 7.36656 16.7273 7.81843 16.7273H12.1821C12.6339 16.7273 13.0002 16.361 13.0002 15.9091C13.0002 15.4572 12.6339 15.0909 12.1821 15.0909H7.81843Z"
                        fill="#006FDF" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4 2C4 0.895431 4.89543 0 6 0H14C15.1046 0 16 0.89543 16 2V4H18C19.1046 4 20 4.89543 20 6V14C20 15.1046 19.1046 16 18 16H16V18C16 19.1046 15.1046 20 14 20H6C4.89543 20 4 19.1046 4 18V16H2C0.895431 16 0 15.1046 0 14V6C0 4.89543 0.895431 4 2 4H4V2ZM6 1.8H14C14.1105 1.8 14.2 1.88954 14.2 2V3.4H5.8V2C5.8 1.88954 5.88954 1.8 6 1.8ZM18 14.2H16V12.4777C16.4008 12.3868 16.7 12.0283 16.7 11.6C16.7 11.103 16.2971 10.7 15.8 10.7H3.80005C3.30299 10.7 2.90005 11.103 2.90005 11.6C2.90005 12.0971 3.30299 12.5 3.80005 12.5H4V14.2H2C1.88954 14.2 1.8 14.1105 1.8 14V6C1.8 5.88954 1.88954 5.8 2 5.8H18C18.1105 5.8 18.2 5.88954 18.2 6V14C18.2 14.1105 18.1105 14.2 18 14.2ZM14.2 12.8H5.8V18C5.8 18.1105 5.88954 18.2 6 18.2H14C14.1105 18.2 14.2 18.1105 14.2 18V12.8Z"
                        fill="#006FDF" />
                    </svg>
                    <span>Print Audit</span>
                  </a>
                </div>
              </div>
              <div class="flight-detatil-info row">
                <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                  <div class="info-field">
                    <label> {{ pageDesc.STATION }} </label>

                    <span class="value"> {{ sweepData.stationCode }}</span>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                  <div class="info-field">
                    <label> {{ pageDesc.ACC_FLAG }} </label>

                    <span class="value">
                      {{ sweepData.faaRegistrationNum }}</span>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                  <div class="info-field">
                    <label> {{ pageDesc.SHIP }} </label>

                    <span class="value"> {{ sweepData.shipNum }}</span>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-0">
                  <div class="info-field">
                    <label> {{ pageDesc.DATE }} </label>

                    <span class="value"> {{ sweepData.departureDate }}</span>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3 flight-select-container">
                <div class="info-field">
                  <label> {{ pageDesc.FLIGHT_APPLIED }} </label>
                  <span class="value">
                    {{
                    sweepData.cadVisual
                    ? pageDesc.CANADIAN_VISUAL
                    : pageDesc.FULL
                    }}</span>
                </div>
              </div>
            </div>

            <div class="flight-details-input-wrapper">
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-check-checkbox mb-3 form-check-blue">
                    <input class="form-check-input me-0 me-md-2" type="checkbox" disabled="disabled" [checked]="sweepData.mainLine"  id="mainLine" />
                    <label class="form-check-label" for="station">
                      {{ pageDesc.INTERNATIONAL_STATION }}</label>
                  </div>
                </div>
              </div>

              <div class="row flight-details-input" *ngIf="sweepData.mainLine">
                <div class="col-12 d-flex justify-content-between flex-column flex-md-row">
                  <div class="form-group field mb-3 mb-md-0 justify-content-between">
                    <label class="col-form-label">
                      {{ pageDesc.OUTBOUND_FLIGHT_NUMBER }}</label>
                    <span class="value"> {{ sweepData.flightNum }}</span>
                  </div>
                  <div class="form-group field mb-3 mb-md-0 justify-content-between">
                    <label class="col-form-label flight-origin col-sm-8 ms-0">
                      {{ pageDesc.FLIGHT_ORIGIN }}</label>
                    <span class="value"> {{ sweepData.flightOrigin }}</span>
                  </div>
                  <div class="form-group field">
                    <label class="col-form-label col-sm-8">
                      {{ pageDesc.FLIGHT_DESTINATION }}</label>
                    <span class="value">
                      {{ sweepData.flightDestination }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="sweep-details-wrapper mt-4">
              <div class="row w-100">
                <ng-container *ngFor="let item of sweepData.sweepDetails; let i = index">
                  <div class="aircraft-detail interior col-sm-4 col-md-4">
                    <div class="sweep-details">
                      <div class="notes-and-sweep-details" [ngClass]="{ bar: i !== 0 }"
                        *ngIf="(sweepData.cadVisual && item.typeOfSweep === SweepType.INTERIOR) || !sweepData.cadVisual">
                        <div class="sweep-time">
                          <label class="col-form-label">{{ item.typeOfSweep === 'INT' ? 'Aircraft Interior' :
                            (item.typeOfSweep === 'BIN' ? 'Aircraft Baggage / Cargo Bins' : 'Aircraft Exterior')
                            }}</label>
                          <span class="time-input">{{ item.locInspectorTimeStamp }}
                          </span>
                        </div>
                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label">
                            {{ pageDesc.COMPLETED_DATE }}:</label>
                          <div class="comp-dt">
                            {{
                            item.inspectorTimeStamp
                            | date : "YYYY-MM-dd"
                            }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label">
                            {{ pageDesc.NOTES }}:</label>
                          <div class="notes-wrapper notes">
                            {{ item.notes }}
                          </div>
                        </div>

                        <div class="notes-container mt-2">
                          <label class="col-form-label notes-label">
                            {{ pageDesc.SEARCH_AGENTS }}:</label>
                          <div class="notes-wrapper"></div>
                        </div>

                        <div class="notes-container mt-2">
                          <div class="inspectors">
                            <div class="inspectors-list">
                              <label>
                                {{ item.crewTeam }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-loader *ngIf="loading">
  <span style='margin-right: 8px;'>Loading...</span>
</app-loader>
