import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { OAuthModuleConfig, OAuthStorage } from "angular-oauth2-oidc";

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { SweepService } from '../shared/service/sweep.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  constructor(
    private readonly sweepService: SweepService,
    private readonly logger: NGXLogger
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const transactionId = uuidv4();
    const deviceId = this.sweepService.getDeviceId();
    const applicationId = this.sweepService.getAppId();

    //Temp fix for headers. need to write the separate interceptor for Auth and Application Service.
    if (request.url.includes('securitySweep')) {
      const modifiedRequest = request.clone({
        headers: request.headers
          .set('accept', 'application/json')
          .set('transactionId', transactionId)
          .set('deviceId', deviceId)
          .set('applicationId', applicationId)
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Methods', '*')
          .set('Access-Control-Allow-Headers', '*')
          .set('X-Delta_SSW_Test', 'ad9ce362647b3197c320488467042cffbee44655e84438ada7dc9ff724904a20'),
       //   .set('X-Delta-Digital_Sweep_Sheet', 'cca74321b740f066fab9b0fbe314cec891ad8045971440afea196b9129c6f95e'),
        // .set(environment.apigwId, environment.apigwValue),
      });
      return next.handle(modifiedRequest);
    } else if (request.url.includes('shipCurrentOperatingLegStatus')) {
      const modifiedRequest = request.clone({
        headers: request.headers
          .set('accept', 'application/json')
          .set('transactionId', transactionId)
          .set('originalChannelId', 'SecuritySweep')
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Methods', '*')
          .set('Access-Control-Allow-Headers', '*'),
      });
      return next.handle(modifiedRequest);
    } else {
      const modifiedRequest = request.clone({
        headers: request.headers
          .set('accept', 'application/json')
      });
      return next.handle(modifiedRequest);
    }


  }
}
