import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Location } from '@angular/common';
import { UserModel } from 'src/app/model/User.model';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements OnInit {
  date = new Date();

  public user!: UserModel;
  public displayUser: boolean;

  constructor(private userService: UserService, private router: Router) {
    this.displayUser = true;
  }

  ngOnInit(): void {
    this.user = this.userService.getUserInformation();
    this.displayUser = !this.router.url.includes('sweep-report');

    setInterval(() => {
      this.date = new Date();
    }, 100);
  }
}
