<!-- Begin page -->
<div class="content-page">
  <div class="content">
    <!-- Start Content-->
    <div class="container-fluid">
      <div *ngIf="displayUser" class="row">
        <div class="col-12">
          <div class="d-none d-md-flex page-title">
            <div class="page-title-left">
              <h4 class="welcome">Welcome {{ user.given_name }} {{ user.family_name}}</h4>
            </div>

            <div class="page-title-right">
              <!--<div class="address me-4">ATL - Atlanta, GA</div>-->
              <div class="date me-4">
                {{ date | date: "mediumDate" }}
              </div>


              <div class="time">
                {{ date | date: "HH:mm" }}
              </div>
            </div>
          </div>
          <div class="d-md-none justify-content-between page-title">
            <div class="d-flex flex-column flex-md-row page-title-left-sm">
              <h4 class="welcome">Welcome {{ user.given_name }} {{ user.family_name}}</h4>
              <!--<div class="address me-md-4">ATL - Atlanta, GA</div>-->
            </div>

            <div class="d-flex flex-column flex-md-row page-title-right-sm">
              <div class="date me-md-4">
                {{ date | date: "mediumDate" }}
              </div>

              <div class="time mt-3 mt-md-none d-md-flex">
                {{ date | date: "HH:mm" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>

      <div class="row">
        <div class="col-12">
          <p class="description">
            Warning: This record contains sensitive security information that is
            controlled under 49 c.f.r parts 15 and 1520. no part of this record
            may be disclosed to person without a "need to know" as defined in 49
            c.f.r. parts 15 and 1520, except with the written permission of the
            administrator of the transportation security or the secretary of
            transportation, unauthorized release may result in civil penalties
            or the other . for U.S government agencies, public disclosure
            governed by 5 U.S.C 552 and 49 c.f.r. parts 15 and 1520.
          </p>
        </div>
        <div class="col-12 copyright">
          <p>
            <span>Copyright @ Delta Airlines</span>
          </p>

          <p>
            <span>
              Support: <a
                href="mailto:ACSITDigitalFormsSupport@delta.com">ACSITDigitalFormsSupport@delta.com</a>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>v3.1.2 September 4th, 2024</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
